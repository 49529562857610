import React, { useEffect } from 'react'
import './bonus.scss'
import { useDispatch, useSelector } from 'react-redux'
import { formatPrice } from '../../utils/helper'
import { getTotalRewards } from '../../redux-store/redux/thunk/bonus.thunk'

const Bonus = ({ setBonusPopup }) => {
  const dispatch = useDispatch()
  const { totalRewards } = useSelector(state => state.bonus)

  useEffect(() => {
    dispatch(getTotalRewards())
  }, [])
  return (
    <div className='modal fade show' id='wallet-modal' style={{ display: 'block' }}>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header py-3 border-0 px-4'>
            <h5 className='modal-title fs-5 text-white ms-2'>Bonus</h5>
            <button
              type='button'
              className='btn-close btn-close-white small'
              onClick={() => {
                setBonusPopup(false)
              }}
            />
          </div>
          <div className='modal-body'>
            <div className='reward-bonus-page'>
              <div className='bonus-data'>
                <div>
                  <h3>Total Claimed Bonus</h3>
                  <p>${formatPrice((+totalRewards?.totalRewarded)) || '0.00'}</p>
                </div>
                <div className='bonus-row'>
                  <div>
                    <h3>Total Registration Bonus</h3>
                    <p>${formatPrice(+totalRewards?.registrationBonus) || '0.00'}</p>
                  </div>
                  <div>
                    <h3>Total Deposit Bonus</h3>
                    <p>${formatPrice(+totalRewards?.depositBonus) || '0.00'}</p>
                  </div>
                  <div>
                    <h3>Total Cashback</h3>
                    <p>${formatPrice(+totalRewards?.totalCashback) || '0.00'}</p>
                  </div>
                  <div>
                    <h3>Total Other Bonus</h3>
                    <p>${formatPrice(+totalRewards?.otherBonuses) || '0.00'}</p>
                  </div>
                  <div>
                    <h3>Total Pending Rewards</h3>
                    <p>${formatPrice(+totalRewards?.totalPendingRewards) || '0.00'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bonus
