import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import DepositSection from '../WalletSections/DepositSection'
import WithdrawSection from '../WalletSections/WithdrawalSection'
import { useSelector } from 'react-redux'
import { cryptoToFiat } from '../../utils/helper'
import { CryptoNetworkList } from '../WalletSections/constant'
import { ROUTE_PATHS } from '../../utils/constants'

const convertReducer = (state, action) => {
  switch (action?.type) {
    case 'toFiatConvert': {
      const { fiatAmount, cryptoAmount } = action?.value
      return ({ ...state, fiatAmount, cryptoAmount })
    }
    case 'toCryptoConvert': {
      const { fiatAmount, cryptoAmount } = action?.value
      return ({ ...state, fiatAmount, cryptoAmount })
    }
    case 'resetConversionState': {
      return ({ fiatAmount: 0, cryptoAmount: 0 })
    }
    default: return ({ ...state })
  }
}

const TABS = Object.freeze({
  DEPOSIT: Symbol('Deposit'),
  WITHDRAW: Symbol('Withdraw')
})

const WalletPopupContent = ({ setWalletPopup }) => {
  const history = useHistory()
  const { addToast } = useToasts()
  const { allCurrencies } = useSelector((state) => state.gameSetting)
  const { user, selectedWallet } = useSelector((state) => state.user)
  const primaryCurrency = useMemo(() => allCurrencies?.find((currency) => currency?.primary)?.code || 'USD', [allCurrencies])
  const [cryptoCurrencies, setCryptoCurrencies] = useState({})
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [selectedCryptoNetwork, setSelectedCryptoNetwork] = useState(null)
  const [selectedTab, setSelectedTab] = useState(TABS.DEPOSIT)
  const [cryptoNetwork, setCryptoNetwork] = useState(Object.keys(CryptoNetworkList)[0])

  const [conversionState, convertDispatch] = useReducer(convertReducer, { fiatAmount: 0, cryptoAmount: 0 })

  useEffect(() => {
    // filter out crypto currencies and map with user wallet's respective currency also set initial selected currency
    if (allCurrencies?.length && user?.wallets?.length) {
      const currencies = {}
      allCurrencies?.forEach((curr) => {
        if (!curr?.isFiat) {
          currencies[curr?.code] = user?.wallets?.find(wallet => wallet?.currencyId === curr?.id)
        }
      })
      if (currencies && currencies[Object.keys(currencies)[0]]) setSelectedCurrency(selectedCurrency ? currencies[selectedCurrency?.currency?.code] : currencies[Object.keys(currencies)[0]])
      setCryptoCurrencies(currencies)
    }
  }, [allCurrencies, user?.wallets])

  useEffect(() => {
    (async () => {
      if (selectedCurrency?.currency?.code && conversionState?.fiatAmount) {
        const convertedValue = await cryptoToFiat(selectedWallet?.currency?.code, selectedCurrency?.currency?.code, conversionState?.fiatAmount)
        const value = { fiatAmount: conversionState?.fiatAmount, cryptoAmount: convertedValue }
        convertDispatch({ type: 'toCryptoConvert', value })
      }
      if (selectedCurrency?.currency?.code) {
        setSelectedCryptoNetwork(CryptoNetworkList[selectedCurrency?.currency?.code][0]?.networkName)
      }
    })()
  }, [selectedCurrency?.currency?.code])

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency)
    setCryptoNetwork(currency?.currency?.code)
    convertDispatch({ type: 'resetConversionState' })
  }

  const handleTabChange = (tab) => {
    if (tab !== selectedTab) {
      setSelectedTab(tab)
      convertDispatch({ type: 'resetConversionState' })
      setSelectedCurrency(cryptoCurrencies[Object.keys(cryptoCurrencies)[0]])
      setCryptoNetwork(Object.keys(CryptoNetworkList)[0])
    }
  }

  const handleConversion = async (e, isFiat) => {
    const amount = e.target.value
    let value = { ...conversionState }
    let convertedValue = 0
    if (isFiat) {
      convertedValue = await cryptoToFiat(selectedWallet?.currency?.code, selectedCurrency?.currency?.code, +amount)
      value = { fiatAmount: +amount, cryptoAmount: +convertedValue }
    } else {
      convertedValue = await cryptoToFiat(selectedCurrency?.currency?.code, selectedWallet?.currency?.code, +amount)
      value = { fiatAmount: +convertedValue, cryptoAmount: +amount }
    }
    convertDispatch({ type: isFiat ? 'toCryptoConvert' : 'toFiatConvert', value })
    return value
  }

  const statusHandler = () => {
    addToast('KYC must be completed', { appearance: 'error' })
    history.push(ROUTE_PATHS.KYC)
    setWalletPopup(true)
  }

  return (
    <>
      <div>
        <ul className='nav nav-pills' id='myTab' role='tablist'>
          <li className='nav-item' role='presentation'>
            <button className='nav-link active' id='deposit-tab' data-bs-toggle='tab' data-bs-target='#deposit' type='button' role='tab' aria-controls='deposit' aria-selected='true' onClick={() => handleTabChange(TABS.DEPOSIT)}>Deposit</button>
          </li>
          <li className='nav-item' role='presentation'>
            {user?.sumsubKycStatus === 'completed'
              ? <button className='nav-link' id='withdraw-tab' data-bs-toggle='tab' data-bs-target='#withdraw' type='button' role='tab' aria-controls='withdraw' aria-selected='false' onClick={() => handleTabChange(TABS.WITHDRAW)}>Withdraw</button>
              : <button className='nav-link' type='button' role='tab' onClick={() => statusHandler()}>Withdraw</button>}
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <DepositSection
            selectedWallet={selectedWallet}
            primaryCurrency={primaryCurrency}
            cryptoCurrencies={cryptoCurrencies}
            selectedCurrency={selectedCurrency}
            selectedNetwork={selectedCryptoNetwork}
            networkList={cryptoNetwork}
            setSelectedNetwork={setSelectedCryptoNetwork}
            conversionState={conversionState}
            setConversionState={convertDispatch}
            handleCurrencyChange={handleCurrencyChange}
            handleConversion={handleConversion}
          />
          <WithdrawSection
            selectedWallet={selectedWallet}
            primaryCurrency={primaryCurrency}
            cryptoCurrencies={cryptoCurrencies}
            selectedNetwork={selectedCryptoNetwork}
            networkList={cryptoNetwork}
            setSelectedNetwork={setSelectedCryptoNetwork}
            selectedCurrency={selectedCurrency}
            conversionState={conversionState}
            setConversionState={convertDispatch}
            selectedTab={selectedTab}
            handleCurrencyChange={handleCurrencyChange}
            handleConversion={handleConversion}
          />
        </div>
      </div>
      {/* <div className='body-footer-box'>
        <p className='pera-text'>Improve your account security with Two-Factor Authentication</p>
        <a href='#' className='btn primary-btn px-lg-4'>Enable Two-Factor Authentication</a>
      </div> */}
    </>
  )
}

export default WalletPopupContent
