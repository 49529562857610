import React, { useEffect, useReducer, useState } from 'react'
import './transaction.scss'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faFlagCheckered
// } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { getBonusTransactions, getUserTransactions } from '../../redux-store/redux/thunk/Users/User'
import { LOADER, PAGINATION_LIMIT } from '../../utils/constants'
import Pagination from '../../components/Pagination'
import moment from 'moment'
import Loader from '../../components/Loader'
import RolloverDetails from './rolloverDetails'

const TRANSACTION_TYPE_TABS = Object.freeze({
  DEPOSIT: Symbol('deposit'),
  WITHDRAW: Symbol('withdraw'),
  BONUS: Symbol('bonus')
})

const TRANSACTION_MODE_TABS = Object.freeze({
  CRYPTO: Symbol('crypto'),
  FIAT: Symbol('fiat')
})

const TRANSACTION_ENUM_TYPE_MAPPING = {
  [TRANSACTION_TYPE_TABS.DEPOSIT]: 'deposit',
  [TRANSACTION_TYPE_TABS.WITHDRAW]: 'withdraw',
  [TRANSACTION_TYPE_TABS.BONUS]: 'bonus'
}

const TRANSACTION_MODE_ENUM_MODE_MAPPING = {
  [TRANSACTION_MODE_TABS.CRYPTO]: 'cryptoPay',
  [TRANSACTION_MODE_TABS.FIAT]: 'game'
}

const currentTabInfoReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_TAB': return { ...state, selectedType: action.value.tabType, selectedMode: action.value.modeType }
    default: return { ...state }
  }
}

const TransactionsSection = () => {
  const dispatch = useDispatch()

  const [currentTabInfo, currentTabInfoDispatch] = useReducer(currentTabInfoReducer, { selectedType: TRANSACTION_TYPE_TABS.DEPOSIT, selectedMode: TRANSACTION_MODE_TABS.CRYPTO })

  const [currentOffset, setCurrentOffset] = useState(0)
  const [showPagination, setShowPagination] = useState(false)
  const [showNextBtn, setShowNextBtn] = useState(true)
  const [rolloverModal, setRolloverModal] = useState(false)
  const [rolloverData, setRolloverData] = useState(false)

  const { transactions, transactionLoading, bonusTransactions } = useSelector((state) => state.user)
  const { allCurrencies } = useSelector((state) => state.gameSetting)

  const { addToast } = useToasts()
  const handleTabAndModeChange = (tabType, modeType) => {
    currentTabInfoDispatch({ type: 'SET_CURRENT_TAB', value: { tabType, modeType } })
    if (TRANSACTION_ENUM_TYPE_MAPPING[tabType] === 'bonus') {
      dispatch(getBonusTransactions({}, addToast))
    } else {
      dispatch(getUserTransactions({
        limit: PAGINATION_LIMIT,
        offset: 0,
        transactionType: TRANSACTION_ENUM_TYPE_MAPPING[tabType],
        paymentGateway: TRANSACTION_MODE_ENUM_MODE_MAPPING[modeType]
      }, addToast))
    }
    setCurrentOffset(0)
  }

  useEffect(async () => {
    dispatch(getUserTransactions({
      limit: PAGINATION_LIMIT,
      offset: currentOffset,
      transactionType: TRANSACTION_ENUM_TYPE_MAPPING[currentTabInfo.selectedType],
      paymentGateway: TRANSACTION_MODE_ENUM_MODE_MAPPING[currentTabInfo.selectedMode]
    }, addToast))
  }, [])

  const handleTabCryptoCurrency = (item) => {
    if (currentTabInfo.selectedType === TRANSACTION_TYPE_TABS.DEPOSIT) {
      return item.moreDetails ? item?.moreDetails?.paid_amount : '0.00'
    }
    return item?.receivedAmount ? item?.receivedAmount : '0.00'
  }

  useEffect(() => {
    if (currentTabInfo?.selectedType && (transactions?.rows?.length || currentOffset > 0)) {
      setShowPagination(true)
      if (transactions?.rows?.length < PAGINATION_LIMIT) {
        setShowNextBtn(true)
      } else {
        setShowNextBtn(false)
      }
    } else {
      setShowPagination(false)
    }
  }, [currentTabInfo?.selectedType, transactions])

  const onPageChange = async (offsetData) => {
    dispatch(getUserTransactions({
      limit: PAGINATION_LIMIT,
      offset: offsetData,
      transactionType: TRANSACTION_ENUM_TYPE_MAPPING[currentTabInfo.selectedType],
      paymentGateway: TRANSACTION_MODE_ENUM_MODE_MAPPING[currentTabInfo.selectedMode]
    }, addToast))

    setCurrentOffset(offsetData)
  }

  const showInfoHandler = (data) => {
    setRolloverModal(true)
    setRolloverData(data)
  }

  return (
    <main className='main-section'>
      <section className='transactions-section profile-inner-section'>
        <div className='container'>
          <h1 className='section-heading'>Transactions</h1>
          <div className='profile-inner-wrap'>
            <div className='tabs-nav-wrap'>
              <ul className='nav' id='pills-tab' role='tablist'>
                <li className='nav-item' role='presentation'>
                  <button className='nav-link active' id='pills-deposits-tab' data-bs-toggle='pill' data-bs-target='#pills-deposits' type='button' role='tab' aria-controls='pills-deposits' aria-selected='true' onClick={() => handleTabAndModeChange(TRANSACTION_TYPE_TABS.DEPOSIT, TRANSACTION_MODE_TABS.CRYPTO)}>Deposits</button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button className='nav-link' id='pills-withdrawals-tab' data-bs-toggle='pill' data-bs-target='#pills-withdrawals' type='button' role='tab' aria-controls='pills-withdrawals' aria-selected='false' onClick={() => handleTabAndModeChange(TRANSACTION_TYPE_TABS.WITHDRAW, TRANSACTION_MODE_TABS.CRYPTO)}>Withdrawals</button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button className='nav-link' id='pills-bonus-tab' data-bs-toggle='pill' data-bs-target='#pills-bonus' type='button' role='tab' aria-controls='pills-bonus' aria-selected='false' onClick={() => handleTabAndModeChange(TRANSACTION_TYPE_TABS.BONUS, TRANSACTION_MODE_TABS.CRYPTO)}>Bonus</button>
                </li>
              </ul>
            </div>
            <div className='tabs-content-wrap'>
              <div className='tabs-content-box'>
                <div className='tab-content' id='pills-tabContent'>
                  <div className='tab-pane fade show active' id='pills-deposits' role='tabpanel' aria-labelledby='pills-deposits-tab'>
                    {
                    currentTabInfo.selectedType !== TRANSACTION_TYPE_TABS.BONUS &&
                      <ul className='nav' id='pills-tab' role='tablist'>
                        <li className='nav-item' role='presentation'>
                          <button className={`nav-link ${currentTabInfo?.selectedMode === TRANSACTION_MODE_TABS.CRYPTO ? 'active' : ''}`} id='pills-depositsCrypto-tab' data-bs-toggle='pill' type='button' role='tab' aria-controls='pills-depositsCrypto' aria-selected='true' onClick={() => handleTabAndModeChange(currentTabInfo.selectedType, TRANSACTION_MODE_TABS.CRYPTO)}>CRYPTO</button>
                        </li>
                        <li className='nav-item' role='presentation'>
                          <button className={`nav-link ${currentTabInfo?.selectedMode === TRANSACTION_MODE_TABS.FIAT ? 'active' : ''}`} id='pills-depositsBanking-tab' data-bs-toggle='pill' type='button' role='tab' aria-controls='pills-depositsBanking' aria-selected='false' onClick={() => handleTabAndModeChange(currentTabInfo.selectedType, TRANSACTION_MODE_TABS.FIAT)}>FIAT</button>
                        </li>
                      </ul>
                    }
                    <div className='tab-content' id='pills-tabContent'>
                      {currentTabInfo.selectedType === TRANSACTION_TYPE_TABS.BONUS
                        ? (
                          <div className='tab-pane fade show active' aria-labelledby='pills-depositsCrypto-tab'>
                            <div className='content-box'>
                              <div className='table-wrap'>
                                <div className='table-responsive'>
                                  <table className='table table-striped table-default'>
                                    <colgroup>
                                      <col span='5' className='table-head-width' />
                                    </colgroup>
                                    <thead>
                                      <tr>
                                        <th scope='col' className='justify-content-center'>Type</th>
                                        <th scope='col' className='justify-content-center'>Time</th>
                                        <th scope='col' className='justify-content-center'>Amount</th>
                                        <th scope='col' className='justify-content-center'>Status</th>
                                        <th scope='col' className='text-end'>More Details</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {bonusTransactions?.userbonusDetails?.length > 0
                                        ? bonusTransactions?.userbonusDetails?.map((item) => {
                                            const currencyCode = item?.bonus?.currencyCode?.toLowerCase()
                                            return (
                                              <tr key={item?.id}>
                                                <td>
                                                  <div className='text-box'>
                                                    {/* <span className='icon'>
                                                        <FontAwesomeIcon icon={faFlagCheckered} />
                                                      </span> */}
                                                    <span className='text'>{item?.bonusType}</span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className='text-box'>
                                                    <span className='text'>{moment(item.updatedAt).format('MMM DD YYYY, h:mm:ss a')}</span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className='text-box justify-content-center'>
                                                    <span className='text'>{item?.bonusAmount?.toFixed(2)}</span>
                                                    <span className='icon'>
                                                      <img src={`/assets/images/currency/currency-${currencyCode}.png`} className='img-fluid' alt='Icon' />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className='text-box justify-content-center'>
                                                    <span className='text'>{item.status}</span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className='text-box justify-content-end'>
                                                    <button onClick={() => showInfoHandler(item)}>
                                                      <span className='icon'>
                                                        i
                                                      </span>
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          })
                                        : (
                                          <tr className='text-center'>
                                            <td colSpan='6'>
                                              {transactionLoading
                                                ? (
                                                  <Loader component={LOADER.TABLE_CELL} />
                                                  )
                                                : 'No data found.'}
                                            </td>
                                          </tr>
                                          )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* <div className='btn-box'>
                                  <button type='button' className='btn secondary-btn'>Download All Deposits</button>
                                </div> */}
                            </div>
                          </div>)
                        : currentTabInfo?.selectedMode === TRANSACTION_MODE_TABS.CRYPTO
                          ? (
                            <div className='tab-pane fade show active' aria-labelledby='pills-depositsCrypto-tab'>
                              <div className='content-box'>
                                <div className='table-wrap'>
                                  <div className='table-responsive'>
                                    <table className='table table-striped table-default'>
                                      <colgroup>
                                        <col span='6' className='table-head-width' />
                                      </colgroup>
                                      <thead>
                                        <tr>
                                          <th scope='col' className='justify-content-center'>Transaction ID</th>
                                          <th scope='col' className='justify-content-center'>Time</th>
                                          <th scope='col' className='justify-content-center'>Amount</th>
                                          <th scope='col' className='justify-content-center'>Amount In USD</th>
                                          <th scope='col' className='justify-content-center'>Status</th>
                                          <th scope='col' className='justify-content-center'>Comment</th>
                                          {/* <th scope='col' className='text-end'>More Details</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {transactions?.rows?.length > 0
                                          ? transactions?.rows?.map((item) => {
                                              const currencyCode = allCurrencies
                                                .find((currency) => currency.id === item.currencyId)
                                                ?.code.toLowerCase()
                                              return (
                                                <tr key={item?.id}>
                                                  <td>
                                                    <div className='text-box'>
                                                      {/* <span className='icon'>
                                                        <FontAwesomeIcon icon={faFlagCheckered} />
                                                      </span> */}
                                                      <span className='text'>{item?.transactionId}</span>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='text-box'>
                                                      <span className='text'>{moment(item.updatedAt).format('MMM DD YYYY, h:mm:ss a')}</span>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='text-box justify-content-center'>
                                                      <span className='text'>{handleTabCryptoCurrency(item)}</span>
                                                      <span className='icon'>
                                                        <img src={`/assets/images/currency/currency-${currencyCode}.png`} className='img-fluid' alt='Icon' />
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='text-box justify-content-center'>
                                                      <span className='text'>{item?.amount?.toFixed(2)}</span>
                                                      <span className='icon'>
                                                        <img src='/assets/images/currency/currency-usd.png' className='img-fluid' alt='Icon' />
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='text-box justify-content-center'>
                                                      <span className='text'>{item.status}</span>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='text-box justify-content-center'>
                                                      <span className='text'>{item.comments || 'No comment added'}</span>
                                                    </div>
                                                  </td>
                                                  {/* <td>
                                                    <div className='text-box justify-content-end'>
                                                      <span className='text'>-1250.000000</span>
                                                      <span className='icon'>
                                                        <img src='/assets/images/stock-images/currency-eth.png' className='img-fluid' alt='Icon' />
                                                      </span>
                                                    </div>
                                                  </td> */}
                                                </tr>
                                              )
                                            })
                                          : (
                                            <tr className='text-center'>
                                              <td colSpan='6'>
                                                {transactionLoading
                                                  ? (
                                                    <Loader component={LOADER.TABLE_CELL} />
                                                    )
                                                  : 'No data found.'}
                                              </td>
                                            </tr>
                                            )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                {/* <div className='btn-box'>
                                  <button type='button' className='btn secondary-btn'>Download All Deposits</button>
                                </div> */}
                              </div>
                            </div>
                            )
                          : (
                            <div className='tab-pane fade show active' role='tabpanel' aria-labelledby='pills-depositsBanking-tab'>
                              <div className='content-box'>
                                <div className='table-wrap'>
                                  <div className='table-responsive'>
                                    <table className='table table-striped table-default'>
                                      <colgroup>
                                        <col span='5' className='w-130' />
                                      </colgroup>
                                      <thead>
                                        <tr>
                                          <th scope='col'>Transaction ID</th>
                                          <th scope='col'>Time</th>
                                          <th scope='col'>Amount</th>
                                          <th scope='col'>Status</th>
                                          <th scope='col'>Comment</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {transactions?.rows?.length > 0
                                          ? transactions?.rows?.map((item) => {
                                              const currencyCode = allCurrencies
                                                .find((currency) => currency.id === item.currencyId)
                                                ?.code.toLowerCase()
                                              return (
                                                <tr key={item?.id}>
                                                  <td>
                                                    <div className='text-box'>
                                                      {/* <span className='icon'>
                                                        <FontAwesomeIcon icon={faFlagCheckered} />
                                                      </span> */}
                                                      <span className='text'>{item?.transactionId}</span>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='text-box'>
                                                      <span className='text'>{moment(item.updatedAt).format('MMM DD YYYY, h:mm:ss a')}</span>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='text-box justify-content-end'>
                                                      <span className='text'>{item?.amount}</span>
                                                      <span className='icon'>
                                                        <img src={`/assets/images/currency/currency-${currencyCode}.png`} className='img-fluid' alt='Icon' />
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='text-box justify-content-end'>
                                                      <span className='text'>{item.status}</span>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='text-box justify-content-end'>
                                                      <span className='text'>{item.comments || 'No comment added'}</span>
                                                    </div>
                                                  </td>
                                                  {/* <td>
                                                    <div className='text-box justify-content-end'>
                                                      <span className='text'>-1250.000000</span>
                                                      <span className='icon'>
                                                        <img src='/assets/images/stock-images/currency-eth.png' className='img-fluid' alt='Icon' />
                                                      </span>
                                                    </div>
                                                  </td> */}
                                                </tr>
                                              )
                                            })
                                          : (
                                            <tr className='text-center'>
                                              <td colSpan='6'>
                                                {transactionLoading
                                                  ? (
                                                    <Loader component={LOADER.TABLE_CELL} />
                                                    )
                                                  : 'No data found.'}
                                              </td>
                                            </tr>
                                            )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            )}
                    </div>
                    <div className='d-flex justify-content-center'>
                      {showPagination && (<Pagination currentOffset={currentOffset} onPageChange={onPageChange} disableNext={showNextBtn} />)}
                    </div>
                  </div>

                  {/* <div className='tab-pane fade' id='pills-withdrawals' role='tabpanel' aria-labelledby='pills-withdrawals-tab'>
                    <ul className='nav' id='pills-tab' role='tablist'>
                      <li className='nav-item' role='presentation'>
                        <button className='nav-link active' id='pills-withdrawalsCrypto-tab' data-bs-toggle='pill' data-bs-target='#pills-withdrawalsCrypto' type='button' role='tab' aria-controls='pills-withdrawalsCrypto' aria-selected='true'>Crypto</button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button className='nav-link' id='pills-withdrawalsBanking-tab' data-bs-toggle='pill' data-bs-target='#pills-withdrawalsBanking' type='button' role='tab' aria-controls='pills-withdrawalsBanking' aria-selected='false'>Banking</button>
                      </li>
                    </ul>
                    <div className='tab-content' id='pills-tabContent'>
                      <div className='tab-pane fade show active' id='pills-withdrawalsCrypto' role='tabpanel' aria-labelledby='pills-withdrawalsCrypto-tab'>
                        <div className='content-box'>
                          <div className='table-wrap'>
                            <div className='table-responsive'>
                              <table className='table table-striped table-default'>
                                <thead>
                                  <tr>
                                    <th scope='col' className='text-start'>Game</th>
                                    <th scope='col'>User</th>
                                    <th scope='col'>Time</th>
                                    <th scope='col' className='text-end'>Bet Amount</th>
                                    <th scope='col' className='text-end'>Multiplier</th>
                                    <th scope='col' className='text-end'>Payout</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array(5).fill('').map((item) => {
                                    return (
                                      <tr key={item}>
                                        <td>
                                          <div className='text-box justify-content-start'>
                                            <span className='icon'>
                                              <FontAwesomeIcon icon={faFlagCheckered} />
                                            </span>
                                            <span className='text'>Mines</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-box'>
                                            <span className='icon'>
                                              <FontAwesomeIcon icon={faFlagCheckered} />
                                            </span>
                                            <span className='text'>Hidden</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-box'>
                                            <span className='text'>3:51 PM</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-box justify-content-end'>
                                            <span className='text'>1250.000000</span>
                                            <span className='icon'>
                                              <img src='/assets/images/stock-images/currency-eth.png' className='img-fluid' alt='Icon' />
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-box justify-content-end'>
                                            <span className='text'>0.00x</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-box justify-content-end'>
                                            <span className='text'>-1250.000000</span>
                                            <span className='icon'>
                                              <img src='/assets/images/stock-images/currency-eth.png' className='img-fluid' alt='Icon' />
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className='btn-box'>
                            <button type='button' className='btn secondary-btn'>Download All Withdrawals</button>
                          </div>
                        </div>
                      </div>

                      <div className='tab-pane fade' id='pills-withdrawalsBanking' role='tabpanel' aria-labelledby='pills-withdrawalsBanking-tab'>
                        <div className='content-box'>
                          <div className='table-wrap'>
                            <div className='table-responsive'>
                              <table className='table table-striped table-default'>
                                <thead>
                                  <tr>
                                    <th scope='col' className='text-start'>Game</th>
                                    <th scope='col'>User</th>
                                    <th scope='col'>Time</th>
                                    <th scope='col' className='text-end'>Bet Amount</th>
                                    <th scope='col' className='text-end'>Multiplier</th>
                                    <th scope='col' className='text-end'>Payout</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array(5).fill('').map((item) => {
                                    return (
                                      <tr key={item}>
                                        <td>
                                          <div className='text-box justify-content-start'>
                                            <span className='icon'>
                                              <FontAwesomeIcon icon={faFlagCheckered} />
                                            </span>
                                            <span className='text'>Mines</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-box'>
                                            <span className='icon'>
                                              <FontAwesomeIcon icon={faFlagCheckered} />
                                            </span>
                                            <span className='text'>Hidden</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-box'>
                                            <span className='text'>3:51 PM</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-box justify-content-end'>
                                            <span className='text'>1250.000000</span>
                                            <span className='icon'>
                                              <img src='/assets/images/stock-images/currency-eth.png' className='img-fluid' alt='Icon' />
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-box justify-content-end'>
                                            <span className='text'>0.00x</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-box justify-content-end'>
                                            <span className='text'>-1250.000000</span>
                                            <span className='icon'>
                                              <img src='/assets/images/stock-images/currency-eth.png' className='img-fluid' alt='Icon' />
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {rolloverModal && <RolloverDetails data={rolloverData} setRolloverModal={setRolloverModal} />}
    </main>
  )
}

export default TransactionsSection
