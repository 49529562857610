import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getUserInfoService,
  updateUserInfoService,
  // withdrawRequest
  getTransactions,
  generateQRCodeFor2fa,
  updatePhoneNumber,
  firstTime2faAuthenticateToken,
  uploadProfilePhotoService,
  getPlacedBets,
  generateWalletAddressService,
  deactivate2faAuthenticateTokenService,
  generateReferralCodeService,
  getActiveReferralBonusService,
  getActiveJoiningBonusService,
  depositRequest,
  withdrawAmountRequest,
  generateServerSeedService,
  changeUserPasswordService,
  getUserFeedBack,
  createUserPartner
} from '../../../../network/services/users.service'
// import setUserData from '../../user'
import { getMetaUser, loginWithOtpService, verifyMetaSignature } from '../../../../network/services/auth.service'
import { signIn } from '../../../../utils/common-services/cookie.services'
import { setLoader, setQrcodeUrl, setTopBetTransactions } from '../../../redux-slices/Users/user'
import { setShowLoginPopup } from '../../../redux-slices/gameSetting'
/**
 * Fetch User info who is logged in
 */
export const fetchUserInformation = createAsyncThunk(
  'user/user-detail',
  async (_, thunkApi) => {
    try {
      const res = await getUserInfoService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMetaUserRecord = createAsyncThunk(
  'user/get-MetaUserRecord', async ({ payload, addToast }, thunkApi) => {
    try {
      const res = await getMetaUser(payload)
      signIn({
        token: res.accessToken
      })
      thunkApi.dispatch(setShowLoginPopup(false))
      addToast('Login successfully', { appearance: 'success' })
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      thunkApi.dispatch(setShowLoginPopup(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const verifyMetaAccountSignature = createAsyncThunk(
  'user/verify-Signature',
  async (_, thunkApi) => {
    try {
      const res = await verifyMetaSignature()
      signIn({
        token: res.accessToken
      })
      // addToast('Login succesfully', { appearance: 'success' })
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const loginWithOtp = createAsyncThunk(
  'user/verify-otp',
  async ({ param, addToast, showLoginOtpHandle }, thunkApi) => {
    try {
      const res = await loginWithOtpService(param)
      signIn({
        token: res.accessToken
      })
      thunkApi.dispatch(showLoginOtpHandle(false))
      addToast('Login successfully', { appearance: 'success' })
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      thunkApi.dispatch(setLoader(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update User info who is logged in
 */
export const updateUserInformation = createAsyncThunk(
  'user/update-profile',
  async ({ params, addToast }, thunkApi) => {
    try {
      const res = await updateUserInfoService(params, addToast)
      addToast('Profile updated', { appearance: 'success' })
      thunkApi.dispatch(fetchUserInformation())

      // onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateProfilePic = createAsyncThunk(
  'user/update-profile-pic',
  async ({ data, addToast, onCloseBtnClick }, thunkApi) => {
    try {
      const res = await uploadProfilePhotoService(data, addToast)
      addToast('Profile updated', { appearance: 'success' })
      onCloseBtnClick()
      // thunkApi.dispatch(fetchUserInformation())
      // onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updatePhone = createAsyncThunk(
  'user/update-phone',
  async (params, addToast, thunkApi) => {
    try {
      const res = await updatePhoneNumber(params, addToast)
      thunkApi.dispatch(fetchUserInformation())
      // addToast('Phone number updated', { appearance: 'success' })
      // onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const firstTime2faAuthentication = createAsyncThunk(
  'user/generate-secret-code',
  async ({ token, addToast, handleClose }, thunkApi) => {
    try {
      const res = await firstTime2faAuthenticateToken(token)
      addToast('Otp verified', { appearance: 'success' })
      thunkApi.dispatch(fetchUserInformation())
      handleClose(false)
      // onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const deactivateFirstTime2faAuthentication = createAsyncThunk(
  'user/2FA-Authentication-deactivated',
  async ({ addToast }, thunkApi) => {
    try {
      const res = await deactivate2faAuthenticateTokenService()
      addToast('2FA Authentication deactivated', { appearance: 'success' })
      thunkApi.dispatch(fetchUserInformation())
      // onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const generateQRCode = createAsyncThunk(
  'user/generate-secret-code',
  async (_, thunkApi) => {
    try {
      const res = await generateQRCodeFor2fa()
      thunkApi.dispatch(setQrcodeUrl(res?.dataURL))
      // onSuccess()
      return res
    } catch (error) {
      // addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getWithdrawRequest = createAsyncThunk(
  'user/update-profile',
  async ({ params, addToast, setWithdrawRequestData }, thunkApi) => {
    try {
      const res = await withdrawRequest(params)
      // onSuccess()
      setWithdrawRequestData(res)
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUserTransactions = createAsyncThunk(
  'user/withdraw',
  async (data, addToast, thunkApi) => {
    try {
      const res = await getTransactions(data)
      // onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  })
export const generateWalletAddress = createAsyncThunk(
  'user/generateWalletAddress',
  async ({ params, addToast }, thunkApi) => {
    try {
      const res = await generateWalletAddressService(params)
      thunkApi.dispatch(fetchUserInformation())
      // setWalletInfo(res)
      // onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const generateReferralCode = createAsyncThunk(
  'user/generateReferralCode',
  async ({ addToast }, thunkApi) => {
    try {
      const res = await generateReferralCodeService()
      thunkApi.dispatch(fetchUserInformation())
      // setWalletInfo(res)
      // onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getActiveJoiningBonus = createAsyncThunk('bonus/get-active-joining-bonus', async (_, thunkApi) => {
  try {
    const res = await getActiveJoiningBonusService()
    return res
  } catch (error) {
    // addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getActiveReferralBonus = createAsyncThunk('bonus/get-active-referral-bonus', async (_, thunkApi) => {
  try {
    const res = await getActiveReferralBonusService()
    return res
  } catch (error) {
    // addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getUserPlacedBets = createAsyncThunk(
  'user/withdraw',
  async (params, thunkApi) => {
    let baseUrl
    const { offset, transactionType, limit, addToast } = params
    if (transactionType === 'wallet') {
      baseUrl = 'user/wallet'
    } else if (transactionType === 'cashout') {
      baseUrl = 'user/cashout'
    } else if (transactionType === 'deposit') {
      baseUrl = 'user/deposit'
    } else if (transactionType === 'jackpot') {
      baseUrl = 'user/jackpot'
    } else if (transactionType === 'mybets') {
      params = {
        limit: limit,
        offset: offset
      }
      baseUrl = 'crash-game/my-bets'
    } else if (transactionType === 'topbets') {
      params = {
        type: params.type,
        limit: limit,
        offset: offset
      }
      baseUrl = 'crash-game/top-bets'
    } else if (transactionType === 'allbets') {
      params = {
        type: params.type,
        limit: limit,
        offset: offset
      }
      baseUrl = 'crash-game/all-bets'
    }
    try {
      const res = await getPlacedBets(params, baseUrl)
      if (transactionType === 'topbets') {
        thunkApi.dispatch(setTopBetTransactions(res))
      }
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const depositAmountRequest = createAsyncThunk(
  'wallet/deposit-amount',
  async ({ values, addToast }, thunkApi) => {
    try {
      const res = await depositRequest(values)
      // addToast('Deposit success', { appearance: 'success' })
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const withdrawRequest = createAsyncThunk(
  'wallet/withdraw-request',
  async ({ values, reset, addToast }, thunkApi) => {
    try {
      const res = await withdrawAmountRequest(values)
      addToast('Withdraw request sent to Admin, Please wait for approval!,', { appearance: 'success' })
      reset()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      reset()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const generateServerSeed = createAsyncThunk('user/generate-server-seed', async (_, thunkApi) => {
  try {
    const res = await generateServerSeedService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const changeUserPassword = createAsyncThunk('user/change-password', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await changeUserPasswordService(payload)
    addToast('Password Changed successfully!,', { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const userFeedBack = createAsyncThunk('user/feed-back', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await getUserFeedBack(payload)
    addToast('Feedback Submitted successfully!,', { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const createPartner = createAsyncThunk('user/partner-program', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await createUserPartner(payload)
    addToast('Details Submitted successfully!,', { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})
