import { createAsyncThunk } from '@reduxjs/toolkit'
import { pixiResetRoulette } from '../../../../GamePixiLogic/AtomicRoulette/scripts/bridge'
import { delay } from '../../../../utils/helper'
// import { setSelectedWallet, setUserData } from '../../../redux-slices/Users/user'
import { getLiveStreamingListingService, getUserTopBetsService, getRoundTopWinsService, getZegoCloudTokenService, placeBetAtomicRouletteGameService, sendHostTipService } from '../../../../network/services/atomicRouletteGame.service'
import { setZegoCloudToken } from '../../../../utils/common-services/cookie.services'

// const updateUserBalanceOnWallet = (thunkApi, payload) => {
//   const { user, selectedWallet } = thunkApi?.getState()?.user
//   const { betDetails } = payload
//   const totalBetAmount = betDetails?.reduce(
//     (acc, cur) => acc + +cur.amount,
//     0
//   )
//   const updatedWalletData = {
//     id: selectedWallet?.id,
//     currencyId: selectedWallet?.currencyId,
//     amount: +selectedWallet?.amount - totalBetAmount
//   }

//   const userWalletData = user?.wallets
//   const updatedUserWalletData = userWalletData?.map(ele => {
//     if (ele.id === updatedWalletData.id) {
//       return { ...ele, amount: updatedWalletData.amount }
//     } else {
//       return ele
//     }
//   })
//   // if (selectedWallet?.currencyId === updatedWalletData?.currencyId) {
//   thunkApi?.dispatch(setSelectedWallet({
//     ...selectedWallet,
//     amount: updatedWalletData.amount
//   }))
//   // }
//   thunkApi?.dispatch(setUserData({
//     ...user,
//     wallets: updatedUserWalletData
//   }))
// }

export const placedBetAtomicRouletteGame = createAsyncThunk('atomic-roulette-game/place-bet',
  async ({ payload, onBallStoppedAfterSpin, addToast, generateRandomClientSeed, isAutoBet }, thunkApi) => {
    try {
      const res = await placeBetAtomicRouletteGameService(payload)
      if (res?.winningNumber !== undefined) {
        // updateUserBalanceOnWallet(thunkApi, payload)
        if (isAutoBet) addToast('Auto Bet Started', { appearance: 'success' })
        setTimeout(() => {
          addToast('Bet Place Successfully', { appearance: 'success' })
        }, 4000)
        await delay(1000)
        generateRandomClientSeed()
      } else {
        pixiResetRoulette()
      }
      return { ...res, game: { id: '5', name: 'atomic-roulette' } }
    } catch (error) {
      pixiResetRoulette()
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getLiveStreamingListing = createAsyncThunk('atomic-roulette-game/live-streaming-listing', async (_, thunkApi) => {
  try {
    const res = await getLiveStreamingListingService()
    return res
  } catch (error) {
    console.log('✌️error --->', error)
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getZegoCloudToken = createAsyncThunk('live-streaming-listing/get-zego-token', async (_, thunkApi) => {
  try {
    const res = await getZegoCloudTokenService()
    setZegoCloudToken(res?.zegocloudToken)
    return res
  } catch (error) {
    console.log('✌️error --->', error)
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const sendHostTip = createAsyncThunk('host/send-tip',
  async ({ payload, addToast }, thunkApi) => {
    try {
      const res = await sendHostTipService(payload)
      addToast('Tip Sent Successfully', { appearance: 'success' })
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getRoundTopWins = createAsyncThunk(
  'user/user-top-wins',
  async (data, _, thunkApi) => {
    try {
      const res = await getRoundTopWinsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUserTopBets = createAsyncThunk(
  'user/user-top-bets',
  async (data, _, thunkApi) => {
    try {
      const res = await getUserTopBetsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
