import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startCase } from 'lodash'
import { useDebounce } from 'react-use'
import { isMobile } from 'react-device-detect'
import { useSearchBarClickHandler } from '../../socket-resources/hooks/useSearchBarClickHandler'
// import { SORT_BY_OPTIONS } from '../../containers/Casino/constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { getCasinoProvidersList } from '../../redux-store/redux/thunk/game'

const ProvidersSearchBar = (props) => {
  const {
    setName,
    setProvider,
    provider,
    showProvidersDropdown,
    sortBy,
    setSortBy,
    gameType,
    setGameType
  } = props
  const { providers } = useSelector((state) => state.games)
  const { casinoCategories } = useSelector((state) => state.casinoGame)
  const dispatch = useDispatch()
  const [searchVal, setSearchVal] = useState('')
  const [providerName, setProviderName] = useState('All')
  const [isProviderDropdownOpen, setIsProviderDropdownOpen] = useState(false)
  const [isGameTypeDropdownOpen, setIsGameTypeDropdownOpen] = useState(false)
  // const [isPopularityDropdownOpen, setIsPopularityDropdownOpen] =
  //   useState(false)
  const providersDropdownRef = useRef(null)
  // const popularityDropdownRef = useRef(null)
  const gameTypeDropdownRef = useRef(null)

  const handleSearchGame = (e) => {
    setSearchVal(e?.target?.value)
  }

  useSearchBarClickHandler(providersDropdownRef, (e) => {
    const wrapper = providersDropdownRef.current
    const dropdown = new window.bootstrap.Dropdown(
      wrapper.querySelector('.dropdown-toggle')
    )
    if (isProviderDropdownOpen) {
      dropdown.hide()
    }
    setIsProviderDropdownOpen(false)
  })

  useSearchBarClickHandler(gameTypeDropdownRef, () => {
    const wrapper = gameTypeDropdownRef.current
    const dropdown = new window.bootstrap.Dropdown(
      wrapper.querySelector('.dropdown-toggle')
    )
    if (isGameTypeDropdownOpen) {
      dropdown.hide()
    }
    setIsGameTypeDropdownOpen(false)
  })

  const toggleProvidersDropdown = useCallback(
    (e) => {
      setIsProviderDropdownOpen(!isProviderDropdownOpen)
      const wrapper = providersDropdownRef.current
      const dropdown = new window.bootstrap.Dropdown(
        wrapper.querySelector('.dropdown-toggle')
      )
      if (isProviderDropdownOpen) {
        dropdown.hide()
      } else {
        dropdown.show()
      }
    },
    [isProviderDropdownOpen]
  )

  const toggleGameTypeDropdownOpen = useCallback(
    (e) => {
      setIsGameTypeDropdownOpen(!isGameTypeDropdownOpen)
      const wrapper = gameTypeDropdownRef.current
      const dropdown = new window.bootstrap.Dropdown(
        wrapper.querySelector('.dropdown-toggle')
      )
      if (isGameTypeDropdownOpen) {
        dropdown.hide()
      } else {
        dropdown.show()
      }
    },
    [isGameTypeDropdownOpen]
  )

  useDebounce(
    () => {
      setName(searchVal)
    },
    1000,
    [searchVal]
  )

  useEffect(() => {
    dispatch(getCasinoProvidersList())
    const body = document.body
    body?.classList.add('overflowHidden')
    return () => {
      body?.classList.remove('overflowHidden')
    }
  }, [])

  return (
    <section className='casino-search d-md-flex align-items-center justify-content-between mb-3'>
      <div className='casino-search-content w-100 me-2 mb-2 mb-md-0'>
        <input
          type='text'
          className='form-control shadow-none'
          value={searchVal}
          id='casinoSearch'
          placeholder='Search Games'
          autoFocus={!isMobile}
          onChange={(e) => {
            handleSearchGame(e)
          }}
        />
        <FontAwesomeIcon icon={faSearch} className='search-icon' />
      </div>
      {showProvidersDropdown && (
        <div className='search-filter-right d-flex align-items-center flex-wrap flex-md-nowrap gap-1 gap-md-3'>
          <div className='btn-group me-2' ref={gameTypeDropdownRef}>
            <button
              className='btn btn-primary dropdown-toggle'
              type='button'
              id='gameTypeDropdown'
              aria-haspopup='true'
              onClick={toggleGameTypeDropdownOpen}
            >
              Game Type: <span>{startCase(gameType)}</span>
            </button>
            <ul
              className='dropdown-menu provider-list search-provider-list'
              aria-labelledby='gameTypeDropdown'
            >
              <div className='provider-list-wrap'>
                <li>
                  <a
                    className={`dropdown-item ${gameType === 'All' ? 'active' : ''
                      }`}
                    href=''
                    onClick={(e) => {
                      e.preventDefault()
                      setGameType('All')
                      toggleGameTypeDropdownOpen(!isGameTypeDropdownOpen)
                    }}
                  >
                    All
                  </a>
                </li>
                {casinoCategories.map((item) => (
                  <li key={item?.id}>
                    <a
                      className={`dropdown-item ${gameType === item?.gameCategory ? 'active' : ''
                        }`}
                      href=''
                      onClick={(e) => {
                        e.preventDefault()
                        setGameType(item?.gameCategory)
                        toggleGameTypeDropdownOpen(!isGameTypeDropdownOpen)
                      }}
                    >
                      {item?.gameCategory}
                    </a>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <div className='btn-group me-2' ref={providersDropdownRef}>
            <button
              className='btn btn-primary dropdown-toggle'
              type='button'
              id='providersDropdown'
              aria-haspopup='true'
              onClick={toggleProvidersDropdown}
            >
              Provider: <span>{startCase(providerName)}</span>
            </button>
            <ul
              className='dropdown-menu provider-list search-provider-list'
              aria-labelledby='providersDropdown'
            >
              <div className='provider-list-wrap'>
                <li>
                  <a
                    className={`dropdown-item ${providerName === 'All' ? 'active' : ''
                      }`}
                    href=''
                    onClick={(e) => {
                      e.preventDefault()
                      setProvider('All')
                      setProviderName('All')
                      toggleProvidersDropdown(!isProviderDropdownOpen)
                    }}
                  >
                    All
                  </a>
                </li>
                {providers?.rows?.map((item) => (
                  <li key={item?.id}>
                    <a
                      className={`dropdown-item ${provider === item?.id ? 'active' : ''
                        }`}
                      href=''
                      onClick={(e) => {
                        e.preventDefault()
                        setProvider(item?.id)
                        setProviderName(item?.name)
                        toggleProvidersDropdown(!isProviderDropdownOpen)
                      }}
                    >
                      {item?.name}
                    </a>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <div className='btn-group'>
            <button
              className='btn btn-primary'
              type='button'
              id='providersPopularity'
              aria-haspopup='true'
              onClick={() => setSortBy(!sortBy)}
            >
              <div className='d-flex justify-content-center align-items-center '>
                <div className='d-flex flex-column lh-sm'><div>A</div><div>Z</div></div>
                {sortBy ? <div className='toggle-arrow'>&darr;</div> : <div className='toggle-arrow'>&uarr;</div>}
              </div>
            </button>
            {/* <ul
              className='dropdown-menu provider-list search-provider-list'
              aria-labelledby='providersPopularity'
            >
              <div className='provider-list-wrap'>
                {Object.keys(SORT_BY_OPTIONS)?.map((key) => (
                  <li key={key}>
                    <a
                      className={`dropdown-item ${sortBy === key ? 'active' : ''}`}
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        togglePopularityDropdown(!isPopularityDropdownOpen)
                        setSortBy(key)
                      }}
                    >
                      {key}
                    </a>
                  </li>
                ))}
              </div>
            </ul> */}
          </div>
        </div>
      )}
    </section>
  )
}

export default React.memo(ProvidersSearchBar)
