import { useDispatch, useSelector } from 'react-redux'
import './casinoPage.scss'

import { useCallback, useEffect, useState } from 'react'
import {
  getAllCasinoCategory,
  getAllCasinoPageGames
} from '../../redux-store/redux/thunk/Games/casinoGame'
import ProvidersSearchBar from '../../components/ProvidersSearchBar'
// import { SORT_BY_OPTIONS } from './constant'
import ContentNotFound from '../../components/ContentNotFound'
import AllGamesCard from '../../components/GamesCard'
import { isEmpty } from 'lodash'
import { setSelectedCasinoGame, setSelectedCasinoGameType } from '../../redux-store/redux-slices/Games/casinoGame'
import { useHistory } from 'react-router-dom'
import { LOADER } from '../../utils/constants'
import Loader from '../../components/Loader'
import { getAuthToken } from '../../utils/common-services/cookie.services'
import { CasinoGameTypeList } from '../../components/SideNavBar/constant'

const CasinoPage = () => {
  const dispatch = useDispatch()
  const isLoggedIn = getAuthToken()
  const history = useHistory()

  const { casinoPageGamesList, casinoGamePageLoading, casinoCategories, selectedCasinoGameType } =
    useSelector((state) => state.casinoGame)
  const [items, setItems] = useState([])
  const [provider, setProvider] = useState('All')
  const [gameName, setGameName] = useState('')
  const [selectedFilter, setSelectedFilter] = useState(selectedCasinoGameType)
  // const [sortBy, setSortBy] = useState(Object.keys(SORT_BY_OPTIONS)[0])
  const [sortBy, setSortBy] = useState(false)
  const routePath = history.location.pathname

  const limit = 30

  useEffect(() => {
    dispatch(getAllCasinoCategory({}))
  }, [])

  useEffect(() => {
    if (routePath !== 'casino' && casinoCategories.length > 0) {
      switch (routePath.split('/')[3]) {
        case (CasinoGameTypeList.BACCARAT):
          dispatch(setSelectedCasinoGameType('Live Baccarat'))
          setSelectedFilter('Live Baccarat')
          break
        case (CasinoGameTypeList.SLOTS):
          dispatch(setSelectedCasinoGameType('Video Slots'))
          setSelectedFilter('Video Slots')
          break
        case (CasinoGameTypeList.GAME_SHOW):
          dispatch(setSelectedCasinoGameType('Game Show'))
          setSelectedFilter('Game Show')
          break
      }
    }
  }, [casinoCategories])

  const fetchMore = () => {
    dispatch(
      getAllCasinoPageGames({
        limit,
        offset: items?.rows?.length,
        providerId: provider !== 'All' ? provider : null,
        gameCategory: selectedFilter !== 'All' ? selectedFilter : null,
        name: !isEmpty(gameName) ? gameName : null,
        sortBy: !sortBy ? 'ASC' : 'DESC'
      })
    )
  }

  const goToPlayGamePage = useCallback(
    async (gameData) => {
      const { gameCode, hasDemo, id } = gameData
      if (isLoggedIn || (!isLoggedIn && hasDemo)) {
        dispatch(setSelectedCasinoGame(gameData))
        history.push(`/casino/play-game/${id}/${gameCode}`)
      }
    },
    [isLoggedIn]
  )

  useEffect(() => {
    if ((routePath.includes('baccarat') || routePath.includes('video-slot') || routePath.includes('game-show')) && selectedCasinoGameType === 'All') { return }
    dispatch(
      getAllCasinoPageGames({
        limit,
        offset: 0,
        providerId: provider !== 'All' ? provider : null,
        name: !isEmpty(gameName) ? gameName : null,
        gameCategory: selectedFilter !== 'All' ? selectedFilter : null,
        sortBy: !sortBy ? 'ASC' : 'DESC'
      })
    )
  }, [provider, selectedFilter, gameName, sortBy])

  useEffect(() => {
    setItems(casinoPageGamesList)
  }, [casinoPageGamesList])
  // useEffect(() => {
  //   dispatch(getCasinoProvidersList());
  //   const body = document.body;
  //   body?.classList.add("overflowHidden");
  //   return () => {
  //     body?.classList.remove("overflowHidden");
  //   };
  // }, []);

  return (
    <>
      <main className='main-section'>
        <section className='container shrink-container'>
          <div className='d-flex'>

            {/* Temporary comment this code may be used in future for reference */}
            {/* <div className='game-filter d-none d-lg-block'>
              <div
                className='nav flex-column nav-pills profile-tabs w-100'
                id='v-pills-tab'
                role='tablist'
                aria-orientation='vertical'
              >
                <button
                  className={`d-flex gap-3 text-light nav-link ${selectedFilter === 'All' ? 'active' : ''
                    }`}
                  id='v-pills-all-games-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#v-pills-all-games'
                  type='button'
                  role='tab'
                  aria-controls='v-pills-all-games'
                  aria-selected='true'
                  onClick={() => setSelectedFilter('All')}
                >
                  <span className='icon'>
                    <LiveCasinoIcon />
                  </span>
                  <span className='text'>All</span>
                </button>
                {casinoCategories.map((filter) => (
                  <button
                    key={filter.id}
                    className={`d-flex gap-3 text-light nav-link ${selectedFilter === filter.gameCategory ? 'active' : ''
                      }`}
                    id='v-pills-messages-tab'
                    data-bs-toggle='pill'
                    data-bs-target='#v-pills-messages'
                    type='button'
                    role='tab'
                    aria-controls='v-pills-messages'
                    aria-selected='false'
                    onClick={() => {
                      setSelectedFilter(filter?.gameCategory)
                    }}
                  >
                    <span className='icon'>
                      <LiveCasinoIcon />
                    </span>
                    <span className='text'>{filter.gameCategory}</span>
                  </button>
                ))}
              </div>
            </div> */}

            <div className='all-game'>
              <div className='search-modal-right-section text-white'>
                <section className='search-modal-heading'>SEARCH GAMES</section>
                <ProvidersSearchBar
                  setName={setGameName}
                  setProvider={setProvider}
                  provider={provider}
                  showProvidersDropdown
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  gameType={selectedFilter}
                  setGameType={setSelectedFilter}
                />
                <section className='global-casino-section'>
                  {items?.rows?.length === 0
                    ? (
                      <ContentNotFound message='No Result Found!!!' />
                      )
                    : (
                      <div className='card-group d-grid'>
                        {items?.rows?.map((game) => {
                          return (
                            <AllGamesCard
                              key={game?.id}
                              game={game}
                              item={game}
                              onGamePlayRedirect={goToPlayGamePage}
                              cardClasses='casino-card'
                              isLoggedIn={isLoggedIn}
                            // favouriteGameHandler={favouriteGameHandler}
                            // actionLoading={actionLoading}
                            // isFavourite={item?.isFavourite}
                            />
                          )
                        })}
                      </div>
                      )}
                  {/* {loading && <Loader variant={LOADER_HANDLER_TYPES.content} />} */}
                  {casinoPageGamesList?.count !== 0 && (
                    <button
                      className='btn btn-outline-primary d-flex mx-auto my-2 pt-2 px-4'
                      disabled={
                        casinoPageGamesList?.rows?.length >=
                        casinoPageGamesList?.count
                      }
                      onClick={() => {
                        if (
                          casinoPageGamesList?.rows?.length <
                          casinoPageGamesList?.count
                        ) {
                          fetchMore()
                        }
                      }}
                    >
                      {casinoGamePageLoading
                        ? (
                          <div style={{ width: '60px' }}>
                            <Loader component={LOADER.TABLE_CELL} />
                          </div>
                          )
                        : (
                            'Load More'
                          )}
                    </button>
                  )}
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default CasinoPage
