import React, { useCallback, useEffect, useState } from 'react'
// import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { setShowSearchPopup } from '../../redux-store/redux-slices/settings'
import CommonPopup from '../CommonPopup'
import { getAllCasinoPageGames } from '../../redux-store/redux/thunk/Games/casinoGame'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import ProvidersSearchBar from '../ProvidersSearchBar'
import ContentNotFound from '../ContentNotFound'
import AllGamesCard from '../GamesCard'
import { getAuthToken } from '../../utils/common-services/cookie.services'
import { setSelectedCasinoGame } from '../../redux-store/redux-slices/Games/casinoGame'
import Loader from '../Loader'
import { LOADER } from '../../utils/constants'
import CasinoPage from '../../containers/Casino'

const SearchPopup = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isLoggedIn = getAuthToken()
  const [items, setItems] = useState()
  const [type, setType] = useState('')
  const [provider, setProvider] = useState('')
  const [gameName, setGameName] = useState('')
  const [name, setName] = useState('')
  const [gameId, setGameId] = useState(null)
  const { casinoPageGamesList, casinoGamePageLoading, casinoCategories, selectedCasinoGameType } =
    useSelector((state) => state.casinoGame)

  useEffect(() => {
    setItems(casinoPageGamesList)
  }, [casinoPageGamesList])

  const limit = 35

  const modalCloseHandler = () => {
    dispatch(setShowSearchPopup(false))
  }

  useEffect(() => {
    dispatch(
      getAllCasinoPageGames({
        limit,
        offset: 0,
        name: !isEmpty(gameName) ? gameName : null
      })
    )
  }, [gameName])

  const fetchMore = () => {
    dispatch(
      getAllCasinoPageGames({
        limit,
        offset: items?.rows?.length,
        name: !isEmpty(gameName) ? gameName : null,
      })
    )
  }

  const goToPlayGamePage = useCallback(
    async (gameData) => {
      const { gameCode, hasDemo, id } = gameData
      if (isLoggedIn || (!isLoggedIn && hasDemo)) {
        dispatch(setSelectedCasinoGame(gameData))
        modalCloseHandler()
        history.push(`/casino/play-game/${id}/${gameCode}`)
      } else {
        dispatch(setShowSearchPopup(false))
      }
    },
    [isLoggedIn]
  )

  return (
    <CommonPopup
      modalCloseHandler={modalCloseHandler}
      id='search_modal'
      modalBodyClasses='p-0'
      modalDialogClass='search-dilog'
      modalContentClass='search-modal'
    >
      <main className='main-section search-container'>
        <section className='container shrink-container'>
          <div className='d-flex'>
            <div className='all-game'>
              <div className='search-modal-right-section text-white'>
                <section className='search-modal-heading'>SEARCH GAMES</section>
                <ProvidersSearchBar
                  setName={setGameName}
                  setProvider={setProvider}
                  provider={provider}
                />
                <section className='global-casino-section'>
                  {items?.rows?.length === 0
                    ? (
                      <ContentNotFound message='No Result Found!!!' />
                      )
                    : (
                      <div className='card-group d-grid'>
                        {items?.rows?.map((game) => {
                          return (
                            <AllGamesCard
                              key={game?.id}
                              game={game}
                              item={game}
                              onGamePlayRedirect={goToPlayGamePage}
                              cardClasses='casino-card'
                              isLoggedIn={isLoggedIn}
                            />
                          )
                        })}
                      </div>
                      )}
                  {casinoPageGamesList?.count !== 0 && (
                    <button
                      className='btn btn-outline-primary d-flex mx-auto my-2 pt-2 px-4'
                      disabled={
                        casinoPageGamesList?.rows?.length >=
                        casinoPageGamesList?.count
                      }
                      onClick={() => {
                        if (
                          casinoPageGamesList?.rows?.length <
                          casinoPageGamesList?.count
                        ) {
                          fetchMore()
                        }
                      }}
                    >
                      {casinoGamePageLoading
                        ? (
                          <div style={{ width: '60px' }}>
                            <Loader component={LOADER.TABLE_CELL} />
                          </div>
                          )
                        : (
                            'Load More'
                          )}
                    </button>
                  )}
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
    </CommonPopup>
  )
}

export default React.memo(SearchPopup)
