import React from 'react'
import './myProfile.scss'
import { useSelector } from 'react-redux'

const MyProfile = ({ setProfilePopup }) => {
  const { user } = useSelector((state) => state.user)
  return (
    <div className='modal fade show' id='wallet-modal' style={{ display: 'block' }}>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header py-3 border-0 px-4'>
            <h5 className='modal-title fs-5 text-white ms-2'>My Profile</h5>
            <button
              type='button'
              className='btn-close btn-close-white small'
              onClick={() => {
                setProfilePopup(false)
              }}
            />
          </div>
          <div className='modal-body'>
            <div className='reward-profit-page'>
              <div className='profit-detail'>
                <p>{user?.userName}</p>
                <p>USER ID: {user?.id}</p>
                <div className='profit-vip-status'>
                  <label>VIP STATUS</label>
                  <div className='progress-bar-container'>
                    <span className='progress-bar' />
                  </div>
                  <p>[ COMING SOON ]</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default MyProfile
