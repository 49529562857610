import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import ErrorMessage from '../ErrorMessage'
import Loader from '../Loader'
import { LOADER } from '../../utils/constants'
import { countryList } from '../../helpers/countries'

const RegisterPopupContent = ({ handleRegisterSubmit, loading, handleRedirectToTnC }) => {
  const { t } = useTranslation('home')
  const [showPassword, setShowPassword] = useState(false)
  const schema = yup.object().shape({
    userName: yup
      .string()
      .required(`${t('signup.errors.userName.required')}`)
      .min(4, `${t('signup.errors.userName.minLength')}`)
      .max(20, `${t('signup.errors.userName.maxLength')}`)
      .matches(/^\w+$/, t('signup.errors.userName.pattern')),
    email: yup
      .string()
      .required(t('signup.errors.email.required'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signup.errors.email.pattern')
      ),
    password: yup
      .string()
      .required(`${t('signup.errors.password.required')}`)
      .min(8, `${t('signup.errors.password.pattern')}`)
      .max(16, `${t('signup.errors.password.pattern')}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('signup.errors.password.pattern')
      ),
    dateOfBirth: yup
      .string()
      .required(`${t('signup.errors.dateOfBirth.required')}`),
    countryCode: yup
      .string(),
    referralCode: yup
      .string()
      .min(4, `${t('signup.errors.userName.minLength')}`)
      .max(20, `${t('signup.errors.userName.maxLength')}`)
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
    resetField
  } = useForm({
    defaultValues: { countryCode: 'US' },
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const showCodeField = watch('showCode')

  useEffect(() => {
    if (!showCodeField) {
      resetField('referrerCode')
    }
  }, [showCodeField])

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev)
  }, [showPassword, setShowPassword])

  const handleRegister = (formData) => {
    const userCountry = countryList?.find(country => country?.country_code === formData?.countryCode)
    const data = { ...formData, country: userCountry.country_name }
    handleRegisterSubmit(data, reset)
  }

  return (
    <form onSubmit={handleSubmit((data) => handleRegister(data))}>
      <div className='row justify-content-center'>
        <div className='col-sm-12'>
          <div className='mb-3'>
            <label className='form-label'>
              {t('signup.email')} <span className='text-danger'>*</span>
            </label>
            <div className='input-group'>
              <input
                type='email'
                className='form-control'
                name='email'
                maxLength={50}
                {...register('email')}
              />
            </div>
            {errors && errors.email && (
              <ErrorMessage
                className='text-danger error-msg login-input small'
                message={errors.email.message}
              />
            )}
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='mb-3'>
            <label className='form-label'>
              {t('signup.username')} <span className='text-danger'>*</span>
            </label>
            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                name='userName'
                maxLength={50}
                autoFocus
                {...register('userName')}

              />
              <span className='helper-text w-100'>
                {t('signup.placeholder.userName')}
              </span>
            </div>
            {errors && errors.userName && (
              <ErrorMessage
                className='text-danger error-msg login-input small'
                message={errors.userName.message}
              />
            )}
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='mb-3'>
            <label className='form-label'>
              {t('signup.password')} <span className='text-danger'>*</span>
            </label>
            <div className='input-group'>
              <input
                type={showPassword ? 'text' : 'password'}
                className='form-control pe-5'
                name='password'
                // maxLength={50}
                {...register('password')}
              />
              <span className='addon addon-right cursor-pointer'>
                <button type='button' className='input-btn' onClick={handleShowPassword}>
                  {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                </button>
              </span>
            </div>
            {errors && errors.password && (
              <ErrorMessage
                className='text-danger error-msg login-input small'
                message={errors.password.message}
              />
            )}
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='mb-3'>
            <label className='form-label'>
              {t('signup.dateOfBirth')} <span className='text-danger'>*</span>
            </label>
            <div className='input-group'>
              <input
                type='date'
                className='form-control'
                name='dateOfBirth'
                min={new Date(
                  new Date().setFullYear(
                    new Date().getFullYear() - 100
                  )
                ).toLocaleDateString('en-CA')}
                max={new Date(
                  new Date().setFullYear(
                    new Date().getFullYear() - 18
                  )
                ).toLocaleDateString('en-CA')}
                {...register('dateOfBirth')}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
            {errors && errors.dateOfBirth && (
              <ErrorMessage
                className='text-danger error-msg login-input small'
                style={{ marginBottom: 0 }}
                message={errors.dateOfBirth.message}
              />
            )}
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='mb-3'>
            <label className='form-label'>
              {t('signup.country')}
            </label>
            <div className='input-group'>
              <select className='form-select' {...register('countryCode')}>
                {countryList?.map(country => (
                  <option key={country.country_code} value={country?.country_code}>{country.country_name}</option>
                ))}
              </select>
            </div>
            {errors && errors.countryCode && (
              <ErrorMessage
                className='text-danger error-msg login-input small'
                style={{ marginBottom: 0 }}
                message={errors.dateOfBirth.message}
              />
            )}
          </div>
        </div>
        {/* <div className='col-sm-12'>
          <div className=ame='form-check mb-3'>
            <input type='checkbox' className='form-check-input' id='codeCheckbox' name='showCode' role='button' {...register('showCode')} />
            <label className='form-check-label' htmlFor='codeCheckbox'>
              Code (Optional)
            </label>
          </div>
        </div> */}
        <div className='col-sm-12'>
          <div className='mb-3'>
            <label className='form-label'>
              {t('signup.promoCode')}
            </label>
            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                name='referrerCode'
                {...register('referrerCode')}
              />
            </div>
            {errors && errors.referrerCode && (
              <ErrorMessage
                className='text-danger error-msg login-input small'
                style={{ marginBottom: 0 }}
                message={errors.referrerCode.message}
              />
            )}
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='btn-box mt-2'>
            <button
              type='submit'
              className='btn secondary-btn-color w-100'
              disabled={loading}
            >
              {loading
                ? (
                  <Loader component={LOADER.BUTTON} />
                  )
                : (
                    t('signup.createNewAccount')
                  )}
            </button>
          </div>
        </div>
        <div className='col-sm-12'>
          <p className='pera-text mb-2 mt-4'>
            <span className='mx-1'>{t('signup.haveAccount')}</span>
            <button
              type='button'
              className='btn text-btn'
              data-bs-target='#loginModal'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
            >
              {t('signup.loginBtn')}
            </button>
          </p>
          <p className='pera-text mb-2'>
            <span className='mx-1'>
              {t('signup.loginDescription')}
            </span>
            <button type='button' className='btn text-btn' onClick={handleRedirectToTnC}>
              {t('signup.privacyPolicyBtn')}
            </button>
            <span className='mx-1'>{t('signup.and')}</span>
            <button type='button' className='btn text-btn' onClick={handleRedirectToTnC}>
              {t('signup.termsBtn')}
            </button>
            <span className='mx-1'>{t('signup.apply')}</span>
          </p>
        </div>
      </div>
    </form>
  )
}

export default RegisterPopupContent
