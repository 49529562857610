import { moment } from './../config/moment'
import { divide } from 'number-precision'

// import {
//   configureChains, createClient
// } from 'wagmi'

// import {
//   EthereumClient,
//   w3mConnectors,
//   w3mProvider
// } from '@web3modal/ethereum'
// import { arbitrum, mainnet, polygon } from 'wagmi/chains'
// import { customize, customSettings } from '../components/Game/scripts/customizations'
import CryptoConvert from 'crypto-convert'
import { DEFAULT_PRECISION } from './constants'

const convert = new CryptoConvert({
  cryptoInterval: 60000, // Crypto prices update interval in ms (default 5 seconds on Node.js & 15 seconds on Browsers)
  fiatInterval: (60 * 1e3 * 60), // Fiat prices update interval (default every 1 hour)
  calculateAverage: true, // Calculate the average crypto price from exchanges
  binance: true, // Use binance rates
  bitfinex: true, // Use bitfinex rates
  coinbase: true, // Use coinbase rates
  kraken: true, // Use kraken rates
  HTTPAgent: null
})

// const chains = [arbitrum, mainnet, polygon]

// const { provider } = configureChains(chains, [
//   w3mProvider({ projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID })
// ])

// export const wagmiClient = createClient({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID, appName: 'web3Modal', chains }),
//   provider
// })

// Web3Modal Ethereum Client
// export const ethereumClient = new EthereumClient(wagmiClient, chains)
export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

export const blockInvalidCharWithDecimal = (e) => {
  const { key, target } = e
  const { value, selectionStart } = target

  // Allow navigation keys
  if (['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'].includes(key)) {
    return
  }

  // Prevent special characters
  if (['e', 'E', '+', '-'].includes(key)) {
    e.preventDefault()
    return
  }

  // Prevent multiple decimal points
  if (key === '.' && value.includes('.')) {
    e.preventDefault()
    return
  }

  // Prevent decimal input after "0" at the start (block 0.12)
  if (value === '0' && key !== '.' && key !== 'Backspace') {
    e.preventDefault()
    return
  }

  // Ensure only two decimal places are allowed
  const regex = /^\d*\.?\d{0,1}$/
  const newValue = value.slice(0, selectionStart) + key + value.slice(selectionStart)

  if (!regex.test(newValue)) {
    e.preventDefault()
  }
}

export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  }
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

// export const updateCustomSetting = (adminCustomSetting) => {
//   customize.default = adminCustomSetting?.default
//   if (adminCustomSetting?.gameThemeBackgroundSetting?.graphColor) {
//     customSettings.graphColor = adminCustomSetting?.gameThemeBackgroundSetting?.graphColor
//   }
//   if (adminCustomSetting?.gameThemeBackgroundSetting?.blastColor) {
//     customSettings.blastColor = adminCustomSetting?.gameThemeBackgroundSetting?.blastColor
//   }
//   if (adminCustomSetting?.gameThemeBackgroundSetting?.gameLogoUrl) {
//     customSettings.customLogoUrl = adminCustomSetting?.gameThemeBackgroundSetting?.gameLogoUrl
//     customize.isLogoChange = true
//   }
//   if (adminCustomSetting?.gameThemeBackgroundSetting?.axisColor) {
//     customSettings.axisColor = adminCustomSetting?.gameThemeBackgroundSetting?.axisColor
//   }

//   if (adminCustomSetting?.gameThemeBackgroundSetting?.backgroundAnimationFileUrl) {
//     customSettings.customBackgroundUrl = adminCustomSetting?.gameThemeBackgroundSetting?.backgroundAnimationFileUrl
//     customize.isBackgroundChange = true
//   }
//   if (adminCustomSetting?.gameThemeBackgroundSetting?.loadingBarAnimationFileUrl) {
//     customSettings.customLoadingBarUrl = adminCustomSetting?.gameThemeBackgroundSetting?.loadingBarAnimationFileUrl
//     customize.isLoadingBarChange = true
//   }
//   if (adminCustomSetting?.gameThemeBackgroundSetting?.loadingBarAnimationFileUrl) {
//     customSettings.customLoadingBarUrl = adminCustomSetting?.gameThemeBackgroundSetting?.loadingBarAnimationFileUrl
//   }

//   if (adminCustomSetting?.gameThemeHeroSetting?.heroAnimationFileUrl) {
//     customSettings.customHeroUrl = adminCustomSetting?.gameThemeHeroSetting?.heroAnimationFileUrl
//     customize.isHeroChange = true
//   }

//   if (adminCustomSetting?.gameThemeHeroSetting?.heroBlastAnimationFileUrl) {
//     customSettings.customHeroBlastUrl = adminCustomSetting?.gameThemeHeroSetting?.heroBlastAnimationFileUrl
//     customize.isHeroBlast = true
//   }
//   if (adminCustomSetting?.gameThemeHeroSetting?.heroLoadingAnimationFileUrl) {
//     customSettings.customHeroLoadingUrl = adminCustomSetting?.gameThemeHeroSetting?.heroLoadingAnimationFileUrl
//     customize.isHeroLoading = true
//   }
//   if (adminCustomSetting?.gameThemeHeroSetting?.heroFlyingAnimationFileUrl) {
//     customSettings.customHeroFlyingUrl = adminCustomSetting?.gameThemeHeroSetting?.heroFlyingAnimationFileUrl
//   }

//   if (adminCustomSetting?.gameThemeBackgroundSetting?.isGraphEnable) {
//     customize.isGraphEnable = adminCustomSetting?.gameThemeBackgroundSetting?.isGraphEnable
//   }
// }

export const momentConvertInTime = (time) => {
  if (time) {
    return moment(time).format('DD MMM HH:mm:ss')
  } else {
    return ''
  }
}

export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1])
    if (e) {
      x *= Math.pow(10, e - 1)
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2)
    }
  } else {
    let e = parseInt(x.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      x /= Math.pow(10, e)
      x += (new Array(e + 1)).join('0')
    }
  }
  return x
}

export const closeAllModals = () => {
  // get modals
  const modals = document.getElementsByClassName('modal')

  // on every modal change state like in hidden modal
  for (let i = 0; i < modals.length; i++) {
    modals[i].classList.remove('show')
    modals[i].setAttribute('aria-hidden', 'true')
    modals[i].setAttribute('style', 'display: none')
  }

  // get modal backdrops
  const modalsBackdrops = document.getElementsByClassName('modal-backdrop')

  // remove every modal backdrop
  for (let i = 0; i < modalsBackdrops.length; i++) {
    document.body.removeChild(modalsBackdrops[i])
  }

  // remove body class modal
  document.body.classList.remove('modal-open')
}

export const closeBootStrapModal = (id) => {
  $(id).modal('hide')
  $('.modal-backdrop').hide()
}

export const closeModal = (id) => {
  // get modals
  const modals = document.getElementById(id)

  modals.classList.remove('show')
  modals.setAttribute('aria-hidden', 'true')
  modals.setAttribute('style', 'display: none')

  // get modal backdrops
  const modalsBackdrops = document.getElementsByClassName('modal-backdrop')

  // remove every modal backdrop
  for (let i = 0; i < modalsBackdrops.length; i++) {
    document.body.removeChild(modalsBackdrops[i])
  }

  // remove body class modal
  document.body.classList.remove('modal-open')
}
// const formatterToCurrency = (currency) => {
//   const formatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency
//   })
//   return formatter
// }

export const cryptoToFiat = async (from, to, amount) => {
  const testCoinToReal = { LTCT: 'LTC' }
  const fromCoin = testCoinToReal[from] || from
  const toCoin = testCoinToReal[to] || to

  await convert.ready()
  return convert[fromCoin][toCoin](Number(amount))
  // return formatterToCurrency(to).format(convert[fromCoin][to](Number(amount)))
}

export function delay (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function delayWithId (ms) {
  let timeoutId
  const promise = new Promise((resolve) => {
    timeoutId = setTimeout(() => {
      resolve()
    }, ms)
  })

  return { promise, timeoutId }
}

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const generateString = (length) => {
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

// Blackjack
export const getCardPoints = (cardList) => {
  let cardPoints = 0
  let acePoints = 0

  cardList.forEach(card => {
    if (card[0] === 1) {
      acePoints += 11
    } else {
      acePoints += card[0]
    }
    cardPoints += card[0]
  })

  if (acePoints > 21) {
    acePoints = cardPoints
  }

  return { acePoints, cardPoints }
}

export function increaseByPercent (totalValue, percentage) {
  return Math.floor(totalValue + (totalValue * percentage) / 100)
}

export function shuffleArray (array) {
  let currentIndex = array.length; let randomIndex

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }

  return array
}

export function handleLiveStatsVisibilityChange (isOpening) {
  if (isOpening) {
    if (document.body.classList.contains('live-stats-data')) document.body.classList.remove('live-stats-data')
    else document.body.classList.add('live-stats-data')
  } else {
    if (document.body.classList.contains('live-stats-data')) document.body.classList.remove('live-stats-data')
  }
}

export const formatPrice = (price, showCurrency = false, digits = 2) => {
  if (!price) return 0
  const numberTypeValue = typeof price === 'string' ? Number.parseFloat(price) : price

  const settings = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }
  if (showCurrency) {
    settings.style = 'currency'
    settings.currency = 'USD'
  }
  return numberTypeValue.toLocaleString('en-US', settings)
}

export const getPrecision = (amount, precision = DEFAULT_PRECISION) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(amount * precisionDivide) / precisionDivide
  return result
}

export const showChipsInFormat = chips => {
  if (chips >= 1000) {
    return `${parseInt(divide(chips, 1000))}K${chips % 1000 === 0 ? '' : '+'}`
  }
  if (chips >= 1000000) {
    return `${parseInt(divide(chips, 1000))}M${chips % 1000 === 0 ? '' : '+'}`
  }

  return chips
}

export const toUtcTime = (timeValue) => {
  /**
   * Convert a time value to UTC.
   * @param {string|Date} timeValue - Time value to convert (e.g., "2022-07-25 14:30:00" or Date object)
   * @returns {Date} - Time value in UTC
   */
  if (typeof timeValue === 'string') {
    timeValue = new Date(timeValue)
  }
  const utcTime = new Date(timeValue.toISOString())
  utcTime.setMinutes(utcTime.getMinutes() - utcTime.getTimezoneOffset())
  return utcTime
}
