import React, { useEffect, lazy, useState, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import {
  setSelectedWallet,
  showLoginOtpHandle
} from '../../redux-store/redux-slices/Users/user'
import Login from '../../containers/Login'
import Register from '../../containers/Register'
import Popup from '../Popup'
import { getAuthToken } from '../../utils/common-services/cookie.services'
import { fetchUserInformation } from '../../redux-store/redux/thunk/Users/User'
import { userLogout } from '../../redux-store/redux/thunk/Auth/Auth'
import { useHistory, useLocation, withRouter, NavLink } from 'react-router-dom'
import {
  getAllCurrency,
  // getEncourageWordsSetting,
  getGameSetting,
  setUpdateAffilateCount
} from '../../redux-store/redux/thunk/gameSetting'
import LoginOtpPopUp from '../../containers/QrCode/OtpPopup'
import {
  setAffilateCode,
  setShowLoginPopup,
  setShowSignupPopup
} from '../../redux-store/redux-slices/gameSetting'
import RoundHistoryModal from '../../containers/AHOriginalsGames/CrashGame/CrashGameResultSection/RoundHistoryModel'
import WalletSetting from '../CurrencyDropdown/WalletSetting'
import { ROUTE_PATHS } from '../../utils/constants'
import Wallet from '../../containers/Wallet'
import ForgetPassword from '../../containers/ForgetPassword'
import GamePopup from '../GamePopup'
import WalletPopupContent from '../GamePopupContent/WalletPopupContent'
import './header.scss'
import '../../containers/Casino/casinoPage.scss'
import { setShowNotification, setShowSearchPopup, setShowSideBetList } from '../../redux-store/redux-slices/settings'
import { BetlistIcon, LogoutIcon, SettingIcon, TransactionsIcon, UserIcon, HeaderSearchIcon, BellSolidIcon } from '../../icons'
import { setAppendNewAllBets } from '../../redux-store/redux-slices/games'
import AllRoundHistory from '../../containers/AHOriginalsGames/CrashGame/CrashGameResultSection/AllRoundHistoryModal'
import SelectedMyBetHistory from '../../containers/AHOriginalsGames/Dice/DiceGame/components/BetHistoryModal/SelectedMyBetHistory'
import { resetCasinoLaunchData } from '../../redux-store/redux-slices/Games/casinoGame'
import useCurrentGameAllBetsSocket from '../../socket-resources/hooks/useCurrentGameAllBetsSocket'
import { getAllFavoriteGames, getCustomGameDetails } from '../../redux-store/redux/thunk/game'
import SearchPopup from '../SearchPopup'
// import { invert } from 'lodash'
import MyProfile from '../MyProfile'
import Statistics from '../Statistics'
import Bonus from '../Bonus'

const Loader = lazy(() => import('../Loader'))
const WalletSubscription = lazy(() => import('../../components/WalletSubscription'))

const Header = (props) => {
  const { showLandingPage } = props
  const { t } = useTranslation('home')
  const token = !!getAuthToken()
  const isDemoGame = window?.location?.pathname?.match('/demo')
  // const { address, isConnected } = useAccount()
  const [showPopup, setShowPopup] = useState(false)
  const [showWalletSetting, setShowWalletSetting] = useState(false)
  const [showResetMailPopup, setShowResetMailPopup] = useState(false)
  const [showWalletPopup, setShowWalletPopup] = useState(false)
  const [walletPopup, setWalletPopup] = useState(false)
  const [profilePopup, setProfilePopup] = useState(false)
  const [statisticPopup, setStatisticPopup] = useState(false)
  const [bonusPopup, setBonusPopup] = useState(false)

  const { addToast } = useToasts()
  const history = useHistory()
  const { search } = useLocation()
  const affiliateCode = new URLSearchParams(search).get('affiliateCode')
  const { showLoginOtp, loading, showRoundHistoryModel } = useSelector(
    (state) => state.user
  )
  const { gameMusic } = useSelector(
    (state) => state.gameSetting
  )
  const { showCrashGameHistoryModal } = useSelector(
    (state) => state.crashGame
  )
  const { showDiceSelectBetHistory } = useSelector(state => state.diceGame)

  const musicSoundRef = useRef(null)

  const dispatch = useDispatch()
  const { user, selectedWallet } = useSelector((state) => state.user)
  const { systemGameData, allCurrencies, showSignupPopup, showLoginPopup } = useSelector((state) => state.gameSetting)
  const { showSideBetList, showSearchPopup, showNotification } = useSelector(state => state?.settings)
  const { casinoLaunchData } = useSelector(state => state.casinoGame)

  const listenBlackjackGameAllBets = useCurrentGameAllBetsSocket('/blackJack-game')
  const listenRouletteGameAllBet = useCurrentGameAllBetsSocket('/roulette-game')
  const listenDiceGameAllBet = useCurrentGameAllBetsSocket('/dice-game')

  useEffect(() => {
    const cleanBlackjackGameAllBets = listenBlackjackGameAllBets(handleAllBetsRealTime)
    const cleanRouletteGameAllBets = listenRouletteGameAllBet(handleAllBetsRealTime)
    const cleanDiceGameAllBets = listenDiceGameAllBet(handleAllBetsRealTime)
    return () => {
      cleanBlackjackGameAllBets()
      cleanRouletteGameAllBets()
      cleanDiceGameAllBets()
    }
  }, [])

  const handleAllBetsRealTime = ({ data }) => {
    const latestBets = []
    latestBets.push(data.bets)
    if (latestBets?.length) dispatch(setAppendNewAllBets({ latestBets }))
  }

  useEffect(async () => {
    if (token) {
      try {
        dispatch(fetchUserInformation())
        dispatch(getAllFavoriteGames({ data: { offset: 0, limit: 20 } }))
      } catch (e) {
        addToast(e.message, { appearance: 'error' })
      }
    }
  }, [token])

  useEffect(() => {
    if (affiliateCode) {
      dispatch(setShowSignupPopup(true))
      dispatch(setAffilateCode(affiliateCode))
      dispatch(setUpdateAffilateCount({ affiliateCode }))
    }
  }, [window.location.pathname, affiliateCode])

  const handleSignupPopClose = () => {
    if (affiliateCode && !token) {
      dispatch(setShowSignupPopup(false))
      history.push('/')
    }
  }

  const handleLogInPopClose = () => {
    dispatch(setShowLoginPopup(false))
  }

  useEffect(() => {
    // Set user to redux
    // dispatch(getEncourageWordsSetting())
    // dispatch(getActiveReferralBonus())
    // dispatch(getActiveJoiningBonus())
    // if (!gameThemeSetting) {
    //   dispatch(getGameThemeSetting())
    // }
    musicSoundRef.current = new Audio()
    musicSoundRef.current.src = '/assets/images/file_example_MP3_700KB.mp3'
    musicSoundRef.current.loop = true
    dispatch(getCustomGameDetails())
    if (window.location.pathname === '/signup') {
      dispatch(setShowSignupPopup(true))
    } else if (window.location.pathname === '/login') {
      dispatch(setShowLoginPopup(true))
    }

    return () => {
      musicSoundRef.current.pause()
    }
  }, [])
  useEffect(() => {
    // Set user to redux
    if (gameMusic) {
      musicSoundRef?.current?.play()
    } else {
      musicSoundRef.current?.pause()
    }
  }, [gameMusic])

  useEffect(() => {
    setWalletPopup(false)
  }, [walletPopup])

  // const onplayGameSound = (path) => {
  //   if (path) {
  //     dispatch(setGameSound(path))
  //   } else {
  //     dispatch(setGameSound(path))
  //   }
  // }

  // const onplayGameMusic = (path) => {
  //   if (path) {
  //     dispatch(setMusicSound(path))
  //   } else {
  //     dispatch(setMusicSound(path))
  //   }
  // }

  // useEffect(async () => {
  //   if (isConnected && !token) {
  //     reqMetaMaskAccount()
  //   }
  // }, [isConnected])

  useEffect(() => {
    if (user) {
      if (!selectedWallet) {
        if (user?.userSetting?.currencyId) {
          dispatch(setSelectedWallet(user?.userSetting?.currencyId))
        } else {
          const currentWallet =
            user.wallets.find((ele) => ele.primary) || user.wallets[0]
          dispatch(setSelectedWallet(currentWallet))
        }
      }
    }
  }, [user])

  useEffect(() => {
    if (!allCurrencies?.length) dispatch(getAllCurrency())
  }, [allCurrencies])

  useEffect(() => {
    if (!systemGameData?.length) dispatch(getGameSetting())
  }, [systemGameData])

  const handleSelectWallet = useCallback((wallet) => {
    dispatch(setSelectedWallet(wallet))
  }, [])

  const handleLogOut = () => {
    dispatch(userLogout({ history, addToast }))
    // localStorage.clear()
    localStorage.removeItem('rollOver')
    localStorage.removeItem('user')
  }

  const handleLogoClick = useCallback(() => {
    if (casinoLaunchData) dispatch(resetCasinoLaunchData())
  }, [casinoLaunchData])

  const handleSideBetListToggle = useCallback(() => {
    dispatch(setShowSideBetList(!showSideBetList))
  }, [showSideBetList])

  const handleRightSideBar = useCallback(() => {
    dispatch(setShowNotification(!showNotification))
  }, [showNotification])

  return (
    <>
      {/* NEW HEADER DESIGN START */}
      <header className={`sticky-top ${!!showLandingPage && 'd-none'}`}>
        <nav className='navbar'>
          <div className='container p-0 px-lg-3'>
            <div className='d-flex justify-content-center align-items-center'>
              <NavLink to={ROUTE_PATHS.HOME} className='navbar-brand' onClick={handleLogoClick}>
                <img src='/assets/images/logo/logo-1.png' className='img-fluid mx-auto desktop-logo' alt='Brand-logo' />
                <img src='/assets/images/logo/logo-2.png' className='img-fluid mx-auto mobile-logo' alt='Brand-logo' />
              </NavLink>
              <small className='text-white d-none d-xl-block sub-text'>- EXCLUSIVE CRYPTO BETA -</small>
            </div>
            {!!token && !isDemoGame &&
              <WalletSubscription
                handleSelectWallet={handleSelectWallet}
                setShowWalletSetting={setShowWalletSetting}
                setShowWalletPopup={setShowWalletPopup}
              />}
            <div className='menu-wrap'>
              <div className='btn-box'>
                {token
                  ? (
                    <>
                      {/* <button type='button' className='btn text-btn desktop-btn'>
                        <SearchIcon />
                        <span className='ms-2'>Search</span>
                      </button> */}
                      <NavLink
                        className='btn account-btn align-items-center'
                        to={ROUTE_PATHS.REWARDS}
                      >
                        <img
                          src='/assets/images/reward.png'
                          className='account-btn-mid-content d-lg-block'
                        />
                      </NavLink>
                      <button
                        className={`btn btn-primary search-btn border-0 ${showSearchPopup ? 'active' : ''}`}
                        onClick={() => dispatch(setShowSearchPopup(true))}
                      >
                        <HeaderSearchIcon fill='var(--gray-20)' />
                      </button>
                      <div className='dropdown'>
                        <button className='btn text-btn' type='button' id='profileMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                          <UserIcon />
                        </button>
                        <ul className='dropdown-menu dropdown-menu-end' aria-labelledby='profileMenuButton'>
                          <li>
                            <a href='#' className='dropdown-item' data-bs-toggle='modal' data-bs-target='#walletModal'>
                              <img src='/assets/images/common/wallet.png' className='icon' alt='wallet' width={15} />
                              <span className='text'>Wallet</span>
                            </a>
                          </li>
                          <li>
                            <a href='#' className='dropdown-item' onClick={() => setProfilePopup(true)}>
                              <UserIcon />
                              <span className='text'>My Profile</span>
                            </a>
                          </li>
                          <li>
                            <a href='#' className='dropdown-item' onClick={() => setStatisticPopup(true)}>
                              <UserIcon />
                              <span className='text'>Statistics</span>
                            </a>
                          </li>
                          <li>
                            <a href='#' className='dropdown-item' onClick={() => setBonusPopup(true)}>
                              <img src='/assets/images/common/wallet.png' className='icon' alt='wallet' width={15} />
                              <span className='text'>Bonus</span>
                            </a>
                          </li>
                          <li>
                            <NavLink to='/transactions' className='dropdown-item'>
                              <span className='icon'>
                                <TransactionsIcon />
                              </span>
                              <span className='text'>Transactions</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to='/settings' className='dropdown-item'>
                              <span className='icon'>
                                <SettingIcon />
                              </span>
                              <span className='text'>Settings</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={ROUTE_PATHS.KYC} className='dropdown-item'>
                              <span className='icon'>
                                <SettingIcon />
                              </span>
                              <span className='text'>KYC Verification</span>
                            </NavLink>
                          </li>
                          <li>
                            <a href='#' className='dropdown-item' onClick={handleLogOut}>
                              <span className='icon'>
                                <LogoutIcon />
                              </span>
                              <span className='text'>Logout</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <button type='button' className='btn text-btn desktop-btn' id='chatMenuButton' onClick={handleSideBetListToggle} title='Bet List'>
                        <BetlistIcon />
                      </button>
                      <button
                        className={`btn btn-primary search-btn border-0 ${showNotification ? 'active' : ''}`}
                        onClick={handleRightSideBar}
                      >
                        <BellSolidIcon fill='var(--gray-20)' />
                        {/* {newAnnouncements && <div className='chat-notification-icon' />} */}
                      </button>
                    </>
                    )
                  : (
                    <>
                      <button
                        className={`btn btn-primary search-btn border-0 ${showSearchPopup ? 'active' : ''}`}
                        onClick={() => dispatch(setShowSearchPopup(true))}
                      >
                        <HeaderSearchIcon fill='var(--gray-20)' />
                      </button>
                      <button type='button' className='btn text-btn' data-bs-toggle='modal' data-bs-target='#loginModal'>{t('login')}</button>
                      <button type='button' className='btn secondary-btn-color' data-bs-toggle='modal' data-bs-target='#registerModal'>{t('register')}</button>
                    </>
                    )}
              </div>
            </div>
          </div>
        </nav>
      </header>
      {/* NEW HEADER DESIGN END */}

      <Login showLoginPopUp={showLoginPopup} handleLogInPopClose={handleLogInPopClose} />
      <Register setShowPopup={setShowPopup} showSignupPopup={showSignupPopup} handleSignupPopClose={handleSignupPopClose} />
      <ForgetPassword />
      <GamePopup className={walletPopup && 'popupHide'} id='walletModal' title='Wallet'>
        <WalletPopupContent setWalletPopup={setWalletPopup} />
      </GamePopup>
      {loading && <Loader />}

      {showPopup && (
        // Popup after successful reset password link sent
        <Popup
          handleClose={() => {
            setShowPopup(false)
          }}
          content={t('signup.registerEmailPopupContent')}
        />
      )}
      {/* {showSignupPopup && <Register setShowPopup={setShowPopup} />} */}
      {showResetMailPopup && (
        <Popup
          handleClose={() => {
            setShowResetMailPopup(false)
          }}
          content={t('signin.resetMailSentSuccessContent')}
        />
      )}
      {/* {showLoginPopup && <Login />} */}

      {showLoginOtp && (
        <LoginOtpPopUp
          showPopup={showLoginOtp}
          handleClose={showLoginOtpHandle}
        />
      )}
      {showRoundHistoryModel && <RoundHistoryModal />}
      {showDiceSelectBetHistory && <SelectedMyBetHistory />}
      {showCrashGameHistoryModal && <AllRoundHistory />}
      {showWalletSetting && (
        <WalletSetting onClose={() => setShowWalletSetting(false)} />
      )}
      {showWalletPopup && <Wallet setShowWalletPopup={setShowWalletPopup} />}
      {profilePopup && <MyProfile setProfilePopup={setProfilePopup} />}
      {statisticPopup && <Statistics setStatisticPopup={setStatisticPopup} />}
      {bonusPopup && <Bonus setBonusPopup={setBonusPopup} />}
      {showSearchPopup && (
        <SearchPopup setShowSearchPopup={setShowSearchPopup} />
      )}
    </>
  )
}

export default React.memo(withRouter(Header))
