import { useState, useEffect } from 'react'
import { Howl, Howler } from 'howler'
import { useDispatch, useSelector } from 'react-redux'
import { setGameWiseSounds } from '../../../../../../redux-store/redux-slices/gameSetting'

let startBetAudio = null
let placeChipAudio = null
let resultAudio = null

export function loadSound () {
  startBetAudio = new Howl({
    src: ['/music/Game/Roulette/casinoroulette-spin.mp3'],
    loop: false
  })
  placeChipAudio = new Howl({
    src: ['/music/Game/Roulette/Modern UI Sound.wav'],
    loop: false
  })
  resultAudio = new Howl({
    src: ['/music/Game/Roulette/Win_Tone.wav'],
    loop: false
  })
}

export function playStartBetSound () {
  startBetAudio?.play()
}
export function playPlaceChipSound () {
  placeChipAudio?.play()
}
export function playResultSound () {
  resultAudio?.play()
}

export function stopStartBetSound () {
  startBetAudio?.stop()
}
export function stopPlaceChipSound () {
  placeChipAudio?.stop()
}
export function stopResultSound () {
  resultAudio?.stop()
}

const soundManagerHook = () => {
  const dispatch = useDispatch()
  const { gameWiseSounds } = useSelector((state) => state.gameSetting)

  const [volume, setVolume] = useState(1)

  useEffect(() => {
    loadSound()
  }, [])

  useEffect(() => {
    Howler.volume(volume)
  }, [volume])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        Howler.volume(0)
      } else {
        Howler.volume(1)
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const handleVolumeChange = (volume) => {
    setVolume(parseFloat(volume))
    const soundInfo = { game: 'atomic_roulette', volume }
    dispatch(setGameWiseSounds(soundInfo))
    sessionStorage.setItem('gameSounds', JSON.stringify({ ...gameWiseSounds, [soundInfo.game]: soundInfo.volume }))
  }

  useEffect(() => {
    Howler.mute(false)
    return () => {
      Howler.mute(true)
    }
  }, [])

  return {
    setVolume,
    handleVolumeChange,
    gameWiseSounds
  }
}

export default soundManagerHook
