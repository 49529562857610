import React from 'react'
import './contentNotFound.scss'

const ContentNotFound = ({ message }) => {
  return (
    <div className='text-center text-white mt-6 games-not-found mb-3'>
      <span className='text-uppercase'>{message}</span>
    </div>
  )
}

export default React.memo(ContentNotFound)
