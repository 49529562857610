import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllCasinoCategories,
  getAllCasinoGames,
  getCasinoProvidersService,
  launchCasinoGameService
} from '../../../../network/services/casino.service'

export const getAllCasinoGameFromProvider = createAsyncThunk(
  'system/list-casino-game',
  async (params, thunkApi) => {
    try {
      const res = await getAllCasinoGames(params)
      return { res, params }
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCasinoProvidersList = createAsyncThunk(
  'fetch/providers-list',
  async (data, thunkApi) => {
    try {
      let res = await getCasinoProvidersService(data)
      res = { count: res?.count, rows: [...res?.rows] }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getSelectedCasinoGame = createAsyncThunk(
  'system/selected-casino-game',
  async (params, thunkApi) => {
    try {
      const res = await getAllCasinoGames(params)
      return { res, params }
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getAllCasinoCategory = createAsyncThunk(
  'system/list-casino-categories',
  async (params, thunkApi) => {
    try {
      const res = await getAllCasinoCategories(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const launchCasinoGame = createAsyncThunk(
  'st8/launch',
  async ({ payload }, thunkApi) => {
    try {
      const res = await launchCasinoGameService(payload)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getAllCasinoPageGames = createAsyncThunk(
  'system/list-casino-game-games',
  async (data, thunkApi) => {
    try {
      const res = await getAllCasinoGames({ ...data })
      return { res, data }
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
