import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// import { capitalize } from 'lodash'
import { getRecentlyPlayedGames } from '../../redux-store/redux/thunk/game'
import { getAuthToken } from '../../utils/common-services/cookie.services'
import ImageLazyLoad from '../../components/ImageLazyLoad'
import { ROUTE_PATHS } from '../../utils/constants'
import { setSelectedCasinoGame } from '../../redux-store/redux-slices/Games/casinoGame'
import { capitalize } from 'lodash'

const limit = 20
const customGamesPage = {
  crash: {
    path: ROUTE_PATHS.CRASH_GAME,
    imgUrl: '/assets/images/stock-images/crash-img.png'
  },
  blackjack: {
    path: ROUTE_PATHS.BLACKJACKGAME,
    imgUrl: '/assets/images/stock-images/blackjack-img.png'

  },
  roulette: {
    path: ROUTE_PATHS.ROULETTE,
    imgUrl: '/assets/images/stock-images/roulette-img.png'
  },
  dice: {
    path: ROUTE_PATHS.DICE_GAME,
    imgUrl: '/assets/images/stock-images/dice-img.png'
  },
  atomicRoulette: {
    path: ROUTE_PATHS.ATOMIC_ROULETTE,
    imgUrl: '/assets/images/stock-images/atomic-roulette.png'
  }
}

const GAME_TYPES = {
  CUSTOM: 'custom',
  CASINO: 'casino'
}

const RecentlyPlayedGamesSection = () => {
  const dispatch = useDispatch()
  const isLoggedIn = getAuthToken()
  const history = useHistory()
  const { recentlyPlayedGames } = useSelector(state => state.games)
  const [recentGames, setRecentGames] = useState([])

  const goToPlayGamePage = useCallback(async (gameData) => {
    const { gameCode, gameId } = gameData
    if (isLoggedIn) {
      dispatch(setSelectedCasinoGame(gameData))
      history.push(`/casino/play-game/${gameId}/${gameCode}`)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (recentlyPlayedGames?.rows?.length > 0) {
      const recGames = [...new Set(recentlyPlayedGames?.rows?.map(item => item.gameId))].map(id => {
        return recentlyPlayedGames?.rows.find(item => item.gameId === id)
      })
      setRecentGames(recGames)
    }
  }, [recentlyPlayedGames])

  useEffect(() => {
    // Add the class to the body when the component mounts
    document.body.classList.add('home-wrap-styles')
    dispatch(getRecentlyPlayedGames({ offset: 0, limit }))
    // Remove the class from the body when the component unmounts
    return () => {
      document.body.classList.remove('home-wrap-styles')
    }
  }, [])

  const onCustomGameClick = (game) => {
    history.push(customGamesPage[game?.gameName]?.path)
  }

  return (
    <section className='main-section'>
      <section className='home-filter-section'>
        <div className='container'>
          <h2 className='text-white mb-2'>Recently Played Games</h2>
          <div className='casino-container'>
            {recentlyPlayedGames?.count
              ? recentGames?.map(game => {
                  if (game?.type === GAME_TYPES.CUSTOM) {
                    return (
                      <div
                        className='casino-game'
                        type='button'
                        key={`${GAME_TYPES.CUSTOM}${game?.gameId}`}
                        onClick={() => onCustomGameClick(game)}
                      >
                        <div className='theme-card-wrap'>
                          <div className='theme-card'>
                            <div className='casino-img-wrap'>
                              <div className='position-relative'>
                                <ImageLazyLoad
                                  style={{ objectFit: 'contain', height: '20%' }}
                                  src={customGamesPage[game?.gameName]?.imgUrl}
                                  placeholderImg='/assets/images/casino/AH_CASINO_FALLBACK.jpeg'
                                  className='position-absolute bottom-0 mb-2 px-1'
                                />
                                <ImageLazyLoad
                                  style={{ objectFit: 'contain' }}
                                  src='/assets/images/casino/customgame-bg.jpeg'
                                  placeholderImg='/assets/images/casino/customgame-bg.jpeg'
                                />
                                <div className='card-overlay'>
                                  <h4>{capitalize('play now')}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='live-player'>
                          <div className='text-white'>
                            {capitalize(game?.gameName)} Game
                          </div>
                        </div> */}
                      </div>
                    )
                  }
                  return (
                    <div
                      className='casino-game'
                      type='button'
                      key={`${GAME_TYPES.CASINO}${game?.gameId}`}
                      onClick={() => goToPlayGamePage(game)}
                    >
                      <ImageLazyLoad
                        src={game?.image}
                        placeholderImg='/assets/images/casino/AH_CASINO_FALLBACK.jpeg'
                      />
                      {/* <div className='live-player'>
                        <div className='text-white'>
                          {game?.gameName}
                        </div>
                      </div> */}
                    </div>
                  )
                })
              : null}
          </div>
          {!recentlyPlayedGames?.count && <div className='d-flex justify-content-center mt-5 text-white h4'>No games found.</div>}
        </div>
      </section>
    </section>
  )
}

export default RecentlyPlayedGamesSection
