import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { setLoader, showLoginOtpHandle } from '../../redux-store/redux-slices/Users/user'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../../redux-store/redux/thunk/Auth/Auth'
import { useLocation, useHistory } from 'react-router-dom'
import { setShowLoginPopup, setShowSignupPopup } from '../../redux-store/redux-slices/gameSetting'
import { ROUTE_PATHS } from '../../utils/constants'
import GamePopup from '../../components/GamePopup'
import LoginPopupContent from '../../components/GamePopupContent/LoginPopupContent'
import { closeBootStrapModal } from '../../utils/helper'
import './login.scss'

const Login = ({ showLoginPopUp, handleLogInPopClose }) => {
  const { t } = useTranslation('home')
  const { loading } = useSelector(state => state.user)
  const { siteInfo } = useSelector(state => state.gameSetting)

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const onLoginSuccess = () => {
    if (location.pathname === ROUTE_PATHS.DICE_GAME_DEMO) {
      history.replace(ROUTE_PATHS.DICE_GAME)
    }
    dispatch(setShowLoginPopup(false))
    closeBootStrapModal('#loginModal')
    addToast(t('signin.successfullyLoggedIn'), { appearance: 'success' })
  }
  const OnLoginError = (error) => {
    dispatch(setLoader(false))
    if (error[0].description === 'otp required') {
      handleLogInPopClose()
      dispatch(showLoginOtpHandle(true))
    }
    addToast(error[0].description, { appearance: 'error' })
  }
  const handleLoginSubmit = useCallback(async loginDetails => {
    dispatch(setLoader(true))
    dispatch(
      userLogin({
        userNameOrEmail: loginDetails.userNameOrEmail,
        password: loginDetails.password,
        onLoginSuccess,
        OnLoginError
      })
    )
  }, [onLoginSuccess, OnLoginError, dispatch])

  const handleRedirectToTnC = useCallback(() => {
    const info = siteInfo?.find(info => info?.slug === 'terms-condition')
    if (info) {
      closeBootStrapModal('#loginModal')
      history.push(`/site-info/${info?.slug}`)
    }
  }, [siteInfo])

  const modalCloseHandler = () => {
    dispatch(setShowLoginPopup(false))
    dispatch(setShowSignupPopup(false))
  }
  return (
    <>
      {/* <!-- LOGIN MODAL START --> */}
      <GamePopup
        id='loginModal'
        title={t('signin.title')}
        className={`auth-modal-form ${showLoginPopUp ? 'show d-block' : ''}`}
        modalContentClass='login-modal'
        handleClose={handleLogInPopClose}
        handleCloseModal={modalCloseHandler}
      >
        <LoginPopupContent
          id='loginModal'
          handleLoginSubmit={handleLoginSubmit}
          loading={loading}
          handleRedirectToTnC={handleRedirectToTnC}
        />
      </GamePopup>
    </>
  )
}

export default Login
