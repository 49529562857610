import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import ErrorMessage from '../../components/ErrorMessage'
import Loader from '../../components/Loader'
import { LOADER } from '../../utils/constants'
import { createPartner } from '../../redux-store/redux/thunk/Users/User'
import { useToasts } from 'react-toast-notifications'

const PartnerProgramForm = ({ selectedTab, currentTab }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.user)
  const { t } = useTranslation('partnerProgram')
  const { addToast } = useToasts()

  useEffect(() => {
    // Add the class to the body when the component mounts
    document.body.classList.add('landing-wrap-styles')

    // Remove the class from the body when the component unmounts
    return () => {
      document.body.classList.remove('landing-wrap-styles')
    }
  }, [])

  const partnerProgramSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(`${t('errors.firstName')}`)
      .matches(
        /^[a-zA-Z_-]+$/,
        'First name should not contain any special characters'
      )
      .min(1, 'First Name must be atleast 3 characters')
      .max(20, 'First Name should not exceed 25 characters'),
    lastName: yup
      .string()
      .required(`${t('errors.lastName')}`)
      .matches(
        /^[a-zA-Z_-]+$/,
        'Last name should not contain any special characters'
      )
      .min(1, 'Last Name must be atleast 3 characters')
      .max(20, 'Last Name should not exceed 25 characters'),
    email: yup
      .string()
      .required(t('errors.email.required'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('errors.email.pattern')
      ),
    companyName: yup.string().required(`${t('errors.company')}`),
    comment: yup.string().required(`${t('errors.comment')}`)
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(partnerProgramSchema),
    mode: 'all'
  })

  const handleProgramSelection = async (formData) => {
    const payload = { ...formData, partnerType: currentTab }
    dispatch(createPartner({ payload, addToast }))
    reset()
  }

  return (
    <>
      <div
        className={`mt-3 tab-pane fade show ${selectedTab === currentTab ? 'active' : ''
          }`}
      >
        <form
          onSubmit={handleSubmit((data) =>
            handleProgramSelection(data, selectedTab)
          )}
        >
          <div className='row justify-content-center'>
            <div className='col-12 col-sm-6'>
              <div className='mb-3'>
                <label className='form-label'>
                  {t('formFields.firstName')}
                  <span className='text-danger'>*</span>
                </label>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control'
                    name='firstName'
                    {...register('firstName')}
                  />
                </div>
                {errors && errors.firstName && (
                  <ErrorMessage
                    className='text-danger error-msg small'
                    message={errors.firstName.message}
                  />
                )}
              </div>
            </div>

            <div className='col-12 col-sm-6'>
              <div className='mb-3'>
                <label className='form-label'>
                  {t('formFields.lastName')}
                  <span className='text-danger'>*</span>
                </label>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control'
                    name='lastName'
                    {...register('lastName')}
                  />
                </div>
                {errors && errors.lastName && (
                  <ErrorMessage
                    className='text-danger error-msg small'
                    message={errors.lastName.message}
                  />
                )}
              </div>
            </div>

            <div className='col-12 col-sm-6'>
              <div className='mb-3'>
                <label className='form-label'>
                  {t('formFields.email')} <span className='text-danger'>*</span>
                </label>
                <div className='input-group'>
                  <input
                    type='email'
                    className='form-control'
                    name='email'
                    maxLength={50}
                    {...register('email')}
                  />
                </div>
                {errors && errors.email && (
                  <ErrorMessage
                    className='text-danger error-msg small'
                    message={errors.email.message}
                  />
                )}
              </div>
            </div>

            <div className='col-12 col-sm-6'>
              <div className='mb-3'>
                <label className='form-label'>
                  {t('formFields.company')} <span className='text-danger'>*</span>
                </label>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control'
                    name='companyName'
                    {...register('companyName')}
                  />
                </div>
                {errors && errors.companyName && (
                  <ErrorMessage
                    className='text-danger error-msg small'
                    message={errors.companyName.message}
                  />
                )}
              </div>
            </div>

            <div className='col-sm-12'>
              <div className='mb-3'>
                <label className='form-label'>
                  {t('formFields.comment')} <span className='text-danger'>*</span>
                </label>
                <div className='input-group'>
                  <textarea
                    type='text'
                    className='form-control'
                    name='comment'
                    {...register('comment')}
                    style={{ height: '300px', resize: 'none' }}
                  />
                </div>
                {errors && errors.comment && (
                  <ErrorMessage
                    className='text-danger error-msg small'
                    message={errors.comment.message}
                  />
                )}
              </div>
            </div>

            <div className='btn-box mt-2 w-100 d-flex justify-content-center'>
              <button
                className='btn secondary-btn-color d-flex justify-content-center'
                type='submit'
              >
                {loading ? <Loader component={LOADER.BUTTON} /> : 'Submit Form'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default PartnerProgramForm
