import { createSlice } from '@reduxjs/toolkit'
import { getAnnouncements } from '../redux/thunk/announcement.thunk'

const initialState = {
  announcements: null
}

const {
  // actions: {},
  reducer
} = createSlice({
  name: 'announcement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAnnouncements.fulfilled, (state, action) => {
        return {
          ...state,
          announcements: { ...action.payload, rows: action.payload.rows }
        }
      })
  }
})

export default reducer
