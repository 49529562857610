// import { resetGame, start, stop } from './gameManager'

import { playStartBetSound } from '../../../containers/AHOriginalsGames/Roulette/AtomicRoulette/Game/hooks/soundManagerHook'
import { resetGame, start, stop } from './gameManager'

export const pixiStartRoulette = () => {
  start()
  playStartBetSound()
}

export const pixiStopRoulette = (stopNumber, onBallStoppedAfterSpin) => {
  stop(stopNumber, onBallStoppedAfterSpin)
}

export const pixiResetRoulette = () => {
  resetGame()
}
