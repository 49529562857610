import React, { useCallback, useState, useEffect, memo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './bets.scss'
import { CloseXIcon, RedirectIcon } from '../../icons'
import { setShowNotification, setShowSideBetList } from '../../redux-store/redux-slices/settings'
import { HighlightedBetsTabs } from '../HighlightedBetsSection/constant'
import { getCustomGameHighRollerTransactions, getCustomGameTransactions, getMyCustomGameTransactions } from '../../redux-store/redux/thunk/game'
import BetsTable from './BetsTable'
import LanguageSwitcher from '../../components/Header/LanguageSwitcher'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'
import { getAnnouncements } from '../../redux-store/redux/thunk/announcement.thunk'
import moment from 'moment'

const numberOfRecords = 10
const SideBetHistory = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('home')
  const { user: auth } = useSelector((state) => state.user)
  const { showSideBetList, showNotification } = useSelector(state => state?.settings)

  const [betsToShow, setBetsToShow] = useState(null)
  const [selectedtab, setSelectedTab] = useState('')
  const { customGameTransactions, myCustomGameTransactions, customGameHighRollerTransactions, loading: betLoading } = useSelector(state => state.games)

  const { announcements } = useSelector(state => state.announcement)

  const topRef = useRef(null)

  const handleScroll = () => {
    topRef.current?.scrollIntoView({ behavior: 'instant' })
  }

  useEffect(() => {
    handleScroll()
  }, [announcements])

  useEffect(() => {
    if (!selectedtab) setSelectedTab(HighlightedBetsTabs[1].id)
  }, [selectedtab])

  useEffect(() => {
    if (!auth) setSelectedTab(HighlightedBetsTabs[1].id)
    else setSelectedTab(HighlightedBetsTabs[0].id)
  }, [auth?.id])

  useEffect(() => {
    if (auth && selectedtab === 'myBets') {
      if (!myCustomGameTransactions?.bets) dispatch(getMyCustomGameTransactions({ limit: numberOfRecords, offset: 0 }))
    }
  }, [auth?.id, selectedtab, myCustomGameTransactions?.count])

  useEffect(() => {
    if (showSideBetList) {
      if (selectedtab === 'allBets') dispatch(getCustomGameTransactions({ limit: numberOfRecords, offset: 0 }))
      else if (selectedtab === 'highRoller') dispatch(getCustomGameHighRollerTransactions({ limit: numberOfRecords, offset: 0 }))
    } else if (showNotification) {
      dispatch(
        getAnnouncements({
          startDate: moment().subtract(7, 'd').format(),
          endDate: moment().format(),
          limit: 50
        })
      )
    }
  }, [showSideBetList, selectedtab, showNotification])

  useEffect(() => {
    switch (selectedtab) {
      case 'myBets': {
        setBetsToShow(myCustomGameTransactions)
        break
      }
      case 'allBets': {
        setBetsToShow(customGameTransactions)
        break
      }
      case 'highRoller': {
        setBetsToShow(customGameHighRollerTransactions)
        break
      }
      default: setBetsToShow({ count: 0, bets: [] })
    }
  }, [selectedtab, customGameTransactions, myCustomGameTransactions, customGameHighRollerTransactions])

  const handleSideBetListClose = useCallback(() => {
    dispatch(setShowSideBetList(false))
    dispatch(setShowNotification(false))
  }, [showSideBetList, showNotification])

  return (
    <section className={`bets-section ${(showSideBetList || showNotification) ? 'open-bets' : ''}`}>
      {showNotification
        ? (
          <div className='card'>
            <div className='card-header notification-body'>
              <div className='btn-box'>
                <span className='notification-header'>Notifications</span>
                <button type='button' className='btn text-btn desktop-btn' onClick={handleSideBetListClose}>
                  <CloseXIcon />
                </button>
              </div>
            </div>
            <div className='card-body notification-content'>
              <div className='notification-content-scroll'>
                <div id='empty-chat' className='empty-chat' ref={topRef} />
                <div className='notification-pinned-wrap'>
                  {announcements?.rows?.filter(el => el.isPinned)?.slice(0, 10)?.map(announcement =>
                    <div
                      key={announcement?.id}
                      className='chat-announcement-card d-flex justify-content-between align-items-center'
                    >
                      <p>{`${capitalize(announcement?.title)}: ${announcement?.description}`}</p>
                    </div>)}
                </div>
              </div>
            </div>

          </div>)
        : (
          <div className='card'>
            <div className='card-header'>
              <div className='btn-box'>
                <LanguageSwitcher />
                <button type='button' className='btn text-btn desktop-btn'>
                  <RedirectIcon />
                </button>
                <button type='button' className='btn text-btn desktop-btn' onClick={handleSideBetListClose}>
                  <CloseXIcon />
                </button>
              </div>
            </div>
            <div className='card-body'>
              <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                {
                HighlightedBetsTabs?.map((tab) => (
                  <li key={tab.id} className={`nav-item ${!auth && (tab.id === HighlightedBetsTabs[0].id) ? 'disabled' : ''}`} role='presentation'>
                    <button
                      className={`nav-link ${selectedtab === tab.id ? 'active' : ''
                        } ${!auth && (tab.id === HighlightedBetsTabs[0].id) ? 'disabled' : ''}`}
                      id='pills-my-bets-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-my-bets'
                      type='button'
                      role='tab'
                      aria-controls='pills-my-bets'
                      aria-selected='true'
                      onClick={(e) => {
                        setSelectedTab(tab.id)
                      }}
                    >
                      {t(tab.name)}
                    </button>
                  </li>
                ))
              }
              </ul>

              <div className='tab-content' id='pills-tabContent'>
                {HighlightedBetsTabs.map(tab => (
                  <BetsTable key={tab.id} selectedtab={selectedtab} currentTab={tab.id} betsToShow={betsToShow?.bets || []} betLoading={betLoading} />
                ))}
              </div>
            </div>
          </div>)}
    </section>
  )
}

export default memo(SideBetHistory)
