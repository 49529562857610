export const CryptoNetworkList = {
  BTC: [
    { id: 1, networkName: 'Bitcoin', networkValue: 'bitcoin' },
    { id: 2, networkName: 'BNB Smart Chain', networkValue: 'bnb_smart_chain' }
  ],
  ETH: [
    { id: 1, networkName: 'Ethereum', networkValue: 'ethereum' },
    { id: 2, networkName: 'BNB Smart Chain', networkValue: 'bnb_smart_chain' }
  ],
  USDT: [
    { id: 1, networkName: 'Tron', networkValue: 'tron' },
    { id: 2, networkName: 'Ethereum', networkValue: 'ethereum' },
    { id: 3, networkName: 'BNB Smart Chain', networkValue: 'bnb_smart_chain' },
    { id: 4, networkName: 'Solana', networkValue: 'solana' }
  ]
}

export const CryptoWalletNetworks = {
  Bitcoin: 'bitcoin',
  Ethereum: 'ethereum',
  'BNB Smart Chain': 'bnb_smart_chain',
  Solana: 'solana',
  Tron: 'tron'
}
