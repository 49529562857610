import React from 'react'
import { v4 as uuidv4 } from 'uuid'
// import { GameImg } from '../GameImg'
import ImageLazyLoad from '../ImageLazyLoad'

const AllGamesCard = (props) => {
  const { item, onGamePlayRedirect, cardClasses, isLoggedIn } = props

  return (
    <div
      key={item?.id || uuidv4()}
      className={`view-card ${cardClasses || ''}`}
    >
      <div className='theme-card-wrap'>
        <div className='theme-card'>
          <div
            className='casino-img-wrap'
            onClick={() => onGamePlayRedirect(item)}
            data-bs-toggle={
              isLoggedIn || (!isLoggedIn && item?.hasDemo) ? '' : 'modal'
            }
            data-bs-target={
              isLoggedIn || (!isLoggedIn && item?.hasDemo) ? '' : '#loginModal'
            }
          >
            {/* <GameImg imgSrc={item?.image} /> */}
            <ImageLazyLoad
              src={item?.image}
              placeholderImg='/assets/images/casino/customgame-bg.jpeg'
            />
            <div className='card-overlay'>
              <h4>Play Now</h4>
            </div>
          </div>
          {/* <div className='text-white mt-3'>{item.title}</div> */}
        </div>
      </div>
    </div>
  )
}

export default AllGamesCard
