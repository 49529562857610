import React, { Suspense, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import store from './redux-store/store'
import Routes from './routes'
import Loader from './components/Loader'
import { ToastProvider } from 'react-toast-notifications'
import ErrorBoundary from './components/ErrorBoundary'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import CryptoJS from 'crypto-js'

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY

const encryptData = (data) => CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString()
const decryptData = (ciphertext) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY)
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  } catch (error) {
    return null
  }
}

const showLoginModal = () => {
  return new Promise((resolve) => {
    const modal = document.createElement('div')
    modal.style.position = 'fixed'
    modal.style.top = '0'
    modal.style.left = '0'
    modal.style.width = '100vw'
    modal.style.height = '100vh'
    modal.style.background = 'rgba(0, 0, 0, 0.7)'
    modal.style.display = 'flex'
    modal.style.justifyContent = 'center'
    modal.style.alignItems = 'center'
    modal.style.zIndex = '1000'

    modal.innerHTML = `
      <div style="background: var(--primaryDark); padding: 25px; border-radius: 12px;
                  text-align: center; box-shadow: var(--shadows-lg); width: 350px;
                  border: 1px solid var(--borderLight); color: var(--white);">
        <h2 style="margin-bottom: 15px; color: var(--aquaBlue);">Login</h2>
        <form id="loginForm">
          <input type="text" id="username" placeholder="Username" required
                 style="margin-bottom: 12px; padding: 10px; width: 100%; border-radius: 5px;
                 border: 1px solid var(--borderLight); background: var(--inputBG); color: var(--white);"/><br>
          <input type="password" id="password" placeholder="Password" required
                 style="margin-bottom: 15px; padding: 10px; width: 100%; border-radius: 5px;
                 border: 1px solid var(--borderLight); background: var(--inputBG); color: var(--white);"/><br>
          <button type="submit"
                  style="padding: 12px; width: 100%; border: none; border-radius: 5px;
                  background: var(--primaryBtn); cursor: pointer; color: var(--white);
                  font-size: 16px; transition: 0.3s;">
            Login
          </button>
        </form>
      </div>
    `

    document.body.appendChild(modal)

    document.getElementById('loginForm').onsubmit = (event) => {
      event.preventDefault()
      const username = document.getElementById('username').value
      const password = document.getElementById('password').value
      document.body.removeChild(modal)
      resolve({ username, password })
    }
  })
}

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const storedData = sessionStorage.getItem('authData')
    if (storedData) {
      const decrypted = decryptData(storedData)
      if (decrypted && decrypted.authenticated) {
        setIsAuthenticated(true)
        return
      }
    }

    let credentials = []
    try {
      credentials = JSON.parse(process.env.REACT_APP_CREDENTIALS)
    } catch (error) {
      console.error('Error parsing credentials:', error)
      alert('Authentication system error. Please contact support.')
      return
    }

    const authenticateUser = async () => {
      let authenticated = false

      while (!authenticated) {
        const { username, password } = await showLoginModal()

        authenticated = credentials.some(
          (user) => user.userName === username && user.password === password
        )

        if (!authenticated) {
          alert('Invalid credentials. Try again.')
        }
      }

      const encryptedData = encryptData({ authenticated: true })
      sessionStorage.setItem('authData', encryptedData)
      setIsAuthenticated(true)
    }

    authenticateUser()
  }, [])

  useEffect(() => {
    Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve }))).then(() => {
      // document.querySelector('.loading-page').style.display = 'none'
    })
  }, [])

  if (!isAuthenticated) return null
  return (
    <ToastProvider autoDismiss>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Provider>
      </Suspense>
    </ToastProvider>
  )
}

export default App
